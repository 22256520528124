import React from "react";
import { BrowserView } from "react-device-detect";
import CompletedDesktopPage from "./desktop/completed";

function CompletedPage() {
  return (
    <BrowserView>
      <CompletedDesktopPage />
    </BrowserView>
  );
}
export default CompletedPage;
