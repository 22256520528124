import { Link } from "react-router-dom";
import { Button, Container, Image } from "react-bootstrap";
import { SkeletonCustom } from "../../../../component/global/skeleton";
import RightArrow from "../../../../assets/icons/rightArrow.svg";
import ItemGroup from "../../../../component/desktop/card/itemGroup";
import { useTranslation } from "react-i18next";
import Logo from "../../../../assets/images/partners/collab.png";

function Section1C(props) {
  const { t } = useTranslation();
  return (
    <div className="section1c mb-5">
      {props?.loading ? (
        <SkeletonCustom height="300px" />
      ) : (
        <div className="bg">
          <Container className="mb-5 pt-3">
            <div className="d-flex align-items-center justify-content-between fs-5 mt-4 pb-3 text-white">
              <div className="fs-4 fw-bold w-auto" style={{ width: "60%" }}>
                <img
                  className="img-fluid"
                  src={Logo}
                  alt=""
                  style={{ width: "250px", height: "50px" }}
                />
                {/* {t("Pigijo Collaboration With BCA Bank")} */}
              </div>

              <Link
                className="text-white text-decoration-none see-all-1"
                to="/local-experience/Desa%20Wisata"
              >
                {t("see_all")} <Image src={RightArrow} />
              </Link>
            </div>
            <ItemGroup data={props.data} />
            <Button
              as={Link}
              to="/local-experience/Desa%20Wisata"
              className="see-all-2 color w-100 text-center text-info"
              variant="light-info"
            >
              View all
            </Button>
          </Container>
        </div>
      )}
    </div>
  );
}

export default Section1C;
