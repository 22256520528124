import { Carousel, Image } from "react-bootstrap";
import { RWebShare } from "react-web-share";
import ShareIcon from "../../../assets/icons/share.svg";

function HeroProduct(props) {
  return (
    <div
      className="pb-xl text-center bg-image position-relative product-container"
    >
      <Carousel pause={false} controls={false}>
        {props?.picture &&
          props?.picture.map((item, index) => (
            <Carousel.Item key={index}>
              <Image
                src={process.env.REACT_APP_FILE_URL+"/"+item}
                width="100%"
                className="rounded img-product"
              />
            </Carousel.Item>
          ))}
      </Carousel>
      <RWebShare
        data={{
          text: props?.data?.generalInformation?.shortDescription,
          url: window.location.href,
          title: props?.data?.generalInformation?.name,
        }}
      >
        <Image
          role="button"
          src={ShareIcon}
          className="position-absolute top-0 end-0 p-3"
        />
      </RWebShare>
    </div>
  );
}

export default HeroProduct;
