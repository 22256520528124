import React, { useContext } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import UserContext from "../../../../services/user";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    title: yup.object().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    countryCode: yup.object().required(),
    phone: yup.string().required(),
    email: yup.string().email().required(),
  })
  .required();

const schema2 = yup
  .object({
    oldPassword: yup.string().min(6).required(),
    newPassword: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    cNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  })
  .required();

function Profile(params) {
  const { t } = useTranslation();
  const { loading, addMaster } = useContext(UserContext);
  let navigate = useNavigate();
  const {
    control: control1,
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: {
        value: localStorage.getItem("title"),
        label: localStorage.getItem("title"),
      },
      firstName: localStorage.getItem("firstName"),
      lastName: localStorage.getItem("lastName"),
      countryCode: {
        value: localStorage.getItem("countryCode"),
        label: localStorage.getItem("countryCode"),
      },
      phone: localStorage.getItem("phone"),
      email: localStorage.getItem("email"),
    },
  });

  const {
    control: control2,
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm({
    resolver: yupResolver(schema2),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      cNewPassword: "",
    },
  });

  const onSubmit = async (values) => {
    const bodyForm = values;
    bodyForm.countryCode = bodyForm.countryCode?.value;
    bodyForm.title = bodyForm.title?.value;
    let response = await addMaster(
      "user/update-member/" + localStorage.getItem("_id"),
      values,
      "Update Profile Success"
    );
    if (!response?.error) {
      localStorage.setItem(
        "fullName",
        !response?.data?.role
          ? response?.data?.firstName + " " + response?.data?.lastName
          : response?.data?.fullName
      );
      localStorage.setItem("firstName", response?.data?.firstName || "");
      localStorage.setItem("lastName", response?.data?.lastName || "");
      localStorage.setItem("title", response?.data?.salutation || "");
      localStorage.setItem("email", response?.data?.contact?.email);
      localStorage.setItem("countryCode", response?.data?.contact?.countryCode);
      localStorage.setItem("phone", response?.data?.contact?.phone);
    }
  };

  const onSubmit2 = async (values) => {
    let response = await addMaster(
      "user/change-password/",
      values,
      "Update Password Success"
    );
    if (!response?.error) {
      reset2();
    }
  };

  return (
    <>
      <Card className="border-0 shadow-sm mb-4">
        <Card.Body>
          <div className="h5">
            {t("update_profile")}
            <hr></hr>
          </div>
          <div className="mb-4">
            <Controller
              control={control1}
              {...register1("title")}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  ref={ref}
                  className="select"
                  classNamePrefix="select"
                  placeholder={t("title")}
                  isSearchable={false}
                  value={value}
                  onChange={onChange}
                  options={[
                    {
                      value: "Mr",
                      label: "Mr",
                    },
                    {
                      value: "Ms",
                      label: "Ms",
                    },
                    {
                      value: "Mrs",
                      label: "Mrs",
                    },
                  ]}
                />
              )}
            />
            <small className="text-danger">{errors1.title?.message}</small>
          </div>
          <div className="mb-4">
            <Form.Control
              {...register1("firstName")}
              className="control-input mt-2"
              placeholder={t("first_name")}
            />
            <small className="text-danger">{errors1.firstName?.message}</small>
          </div>
          <div className="mb-4">
            <Form.Control
              {...register1("lastName")}
              className="control-input mt-2"
              placeholder={t("last_name")}
            />
            <small className="text-danger">{errors1.lastName?.message}</small>
          </div>
          <div className="mb-4">
            <Form.Control
              {...register1("email")}
              type="email"
              className="control-input mt-2"
              placeholder={t("email_address")}
            />
            <small className="text-danger">{errors1.email?.message}</small>
          </div>
          <div className="mb-4">
            <Row className="mt-2">
              <Col xs="4" sm="4" md="4" lg="4" xl="4">
                <Controller
                  control={control1}
                  name="countryCode"
                  render={({ field: { onChange, value, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      className="select"
                      classNamePrefix="select"
                      isSearchable={false}
                      placeholder="+xx"
                      value={value}
                      onChange={onChange}
                      options={[
                        {
                          value: "+62",
                          label: "+62",
                        },
                      ]}
                    />
                  )}
                />
                <small className="text-danger">
                  {errors1.countryCode?.message}
                </small>
              </Col>
              <Col xs="8" sm="8" md="8" lg="8" xl="8">
                <Form.Control
                  {...register1("phone")}
                  className="control-input"
                  placeholder={t("phone_number")}
                />
                <small className="text-danger">{errors1.phone?.message}</small>
              </Col>
            </Row>
          </div>
          <div className="text-end">
            <Button
              variant="primary"
              className="text-white px-4"
              onClick={handleSubmit1(onSubmit)}
            >
              {t("save")}
            </Button>
          </div>
        </Card.Body>
      </Card>

      <Card className="border-0 shadow-sm">
        <Card.Body>
          <div className="h5">
            {t("update_password")}
            <hr></hr>
          </div>
          <div className="mb-4">
            <Form.Control
              {...register2("oldPassword")}
              type="password"
              className="control-input mb-3"
              placeholder={t("old_password")}
            />
            <small className="text-danger">
              {errors2.oldPassword?.message}
            </small>
          </div>
          <div className="mb-4">
            <Form.Control
              {...register2("newPassword")}
              type="password"
              className="control-input mb-3"
              placeholder={t("new_password")}
            />
            <small className="text-danger">
              {errors2.newPassword?.message}
            </small>
          </div>
          <div className="mb-4">
            <Form.Control
              {...register2("cNewPassword")}
              type="password"
              className="control-input mb-3"
              placeholder={t("retype_new_password")}
            />
            <small className="text-danger">
              {errors2.cNewPassword?.message}
            </small>
          </div>
          <div className="text-end">
            <Button
              variant="primary"
              className="text-white px-4"
              onClick={handleSubmit2(onSubmit2)}
            >
              {t("save")}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default Profile;
