import React from "react";
import DesktopWrapper from "../../layout/desktop/DesktopWrapper";
import ListColabExperience from "./desktop/index";
import HeroSection2 from "../../component/desktop/card/hero2";

function ColabExperiencesPages() {
  return (
    <DesktopWrapper>
      <HeroSection2 active={1} />
      <ListColabExperience />
    </DesktopWrapper>
  );
}

export default ColabExperiencesPages;
