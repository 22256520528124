import FooterDesktop from "./Footer";
import HeaderDesktop from "./Header";
import logoWa from "../../assets/icons/social-media/iconWa.png";
// import logoWa2 from "../../assets/icons/social-media/IconWa3.png";
import logoWa2 from "../../assets/icons/social-media/IconWa4.png";

function DesktopWrapper(props) {
  return (
    <>
      <HeaderDesktop />
      {props.children}
      <div
        className="fixed-bottom right-100"
        style={{ index: "6", left: "initial" }}
      >
        {/* <a href="https://wa.me/6282246598802?text=Hello Min" target="_blank"> */}
        <a
          href="https://wa.me/6282246598802?text=Halo!%20Bolehkah%20saya%20mendapatkan%20informasi%20detail%20lengkap%20tentang%20paket%20Pigijo%3F"
          target="_blank"
        >
          <img src={logoWa2} width="200" alt="WhatsApp" className="logoWa" />
        </a>
      </div>
      <FooterDesktop />
    </>
  );
}

export default DesktopWrapper;
