import React, { useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Card, Form } from "react-bootstrap";
import FooterNavigation from "../footerNavigation";
import { useForm } from "react-hook-form";
import UserContext from "../../../../../services/user";

const schema = yup
  .object({
    companyDetail: yup.object().shape({
      businessIdentity: yup.string().required("Business Identity is required"),
      phone: yup.string().required("Phone Number is required"),
      email: yup
        .string()
        .email("Email must be a valid email")
        .required("Email is required"),
      taxtpayerRegistrationNumber: yup
        .string()
        .required("Tax Registration Number is required"),
    }),
  })
  .required();

function Step2(params) {
  const { data, registerForm } = useContext(UserContext);
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitStep = async (values) => {
    const newData = Object.assign(data, values);
    const response = await registerForm(newData);
    if (response) {
      params.nextStep();
    }
  };

  return (
    <Card className="card-step border-0 shadow-sm">
      <Card.Body>
        <div className="pb-3">Company Details</div>
        <div className="mb-3">
          <Form.Control
            className="control-input"
            placeholder="Business Identity Number"
            {...register("companyDetail.businessIdentity")}
          />
          <small className="text-danger">
            {errors.companyDetail?.businessIdentity?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            className="control-input"
            placeholder="Site/Company Website (Optional)"
            {...register("companyDetail.website")}
          />
          <small className="text-danger">
            {errors.companyDetail?.website?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            className="control-input"
            placeholder="Company Phone"
            {...register("companyDetail.phone")}
          />
          <small className="text-danger">
            {errors.companyDetail?.phone?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            className="control-input"
            placeholder="Company Email"
            {...register("companyDetail.email")}
          />
          <small className="text-danger">
            {errors.companyDetail?.email?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            className="control-input"
            placeholder="Taxpayer Registration Number"
            {...register("companyDetail.taxtpayerRegistrationNumber")}
          />
          <small className="text-danger">
            {errors.companyDetail?.taxtpayerRegistrationNumber?.message}
          </small>
        </div>
        <FooterNavigation
          totalSteps={params.totalSteps}
          currentStep={params.currentStep}
          previousStep={params.previousStep}
          nextStep={handleSubmit(submitStep)}
          disabled={false}
          submit={params.submit}
        />
      </Card.Body>
    </Card>
  );
}

export default Step2;
