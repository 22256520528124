import React from "react";
import { Link } from "react-router-dom";
import orangeVerified from "../../../../assets/icons/orangeVerified.svg";

const GuideCard = ({ user }) => {
  return (
    <>
      <div className="col-lg-3 col-md-4 mb-2">
        <Link
          className="text-decoration-none text-dark"
          to={`/guide/${user._id}`}
        >
          {user ? (
            <div
              className="card text-center"
              style={{ width: "100%", height: "100%" }}
            >
              <img
                src={user.profile}
                alt="Profile"
                className="rounded-circle mt-3 mx-auto"
                style={{ width: "128px", height: "128px" }}
              />
              <div className="card-body pt-3">
                <h6 className="card-title">
                  {user.verif === "true" && (
                    <img
                      src={orangeVerified}
                      alt="Verified"
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "4px",
                      }}
                    />
                  )}
                  {user.name}
                </h6>
                <p className="card-text">{user.region}</p>
                <p className="card-text">{user.price}</p>
                <p className="card-text">
                  {user.language.bahasa01}, {user.language.bahasa02}
                </p>
              </div>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </Link>
      </div>
    </>
  );
};

export default GuideCard;
