import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import AccessibilityOutlineIcon from "../../../assets/icons/accessibility-outline.svg";
import BuildingOutlineIcon from "../../../assets/icons/business-outline.svg";
import { useNavigate } from "react-router-dom";

function PartnerChooseDesktopPage() {
  let navigate = useNavigate();

  return (
    <DesktopWrapper>
      <Container>
        <div className="h4 mt-5 mb-4">Choose Partner Type</div>
        <Row className="mb-5 text-start">
          <Col sm="12" md="12" lg="6" xl="6" className="mb-3">
            <Card
              className="border-0 shadow-sm"
              role="button"
              onClick={() => navigate("/partners/register/individual")}
              style={{minHeight: "220px"}}
            >
              <Card.Body>
                <Image src={AccessibilityOutlineIcon} className="pb-3" />
                <div className="pb-2">Individual</div>
                <div className="small text-muted fw-light pb-4">
                  You register to become a partner as an individual who does not
                  represent any institution, business entity or company
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="12" md="12" lg="6" xl="6">
            <Card
              className="border-0 shadow-sm"
              role="button"
              onClick={() => navigate("/partners/register/company")}
              style={{minHeight: "220px"}}
            >
              <Card.Body>
                <Image src={BuildingOutlineIcon} className="pb-3" />
                <div className="pb-2">Company</div>
                <div className="small text-muted fw-light pb-4">
                  You register to become a partner as an institution, business
                  entity or company
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </DesktopWrapper>
  );
}
export default PartnerChooseDesktopPage;
