import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
} from "react-bootstrap";
import ItemDestination from "./itemDestination";
import UserContext from "../../../../services/user";
import { useTranslation } from "react-i18next";

function Section4() {
  const { t } = useTranslation();
  const { getAllMaster } = useContext(UserContext);
  const [data, setData] = useState([]);

  const getData = async (l) => {
    const newData = await getAllMaster(`menu/destinations`);
    if (newData) {
      setData(newData?.data?.slice(0,4));
    }
  };

  useEffect(() => {
    getData();
  }, [])

  return (
    <Container className="section4">
      <div className="mb-5">
        <div className="d-flex align-items-center justify-content-between fs-5 mt-5 pb-4">
          <div className="fs-4 fw-bold w-75">{t("most_popular_destination")}</div>
        </div>

        <Row className={"overflow-card mb-4 "+(data?.length>1&&("full"))}>
          {data &&
            data.map((item, index) => (
              <Col sm="12" md="12" lg="4" xl="4" key={index} className="mb-3">
                <ItemDestination data={item} />
              </Col>
            ))}
        </Row>
      </div>
      <hr></hr>
    </Container>
  );
}

export default Section4;
