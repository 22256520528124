import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function RegisterModal() {
  const { t } = useTranslation();
  return (
    <>
      <Nav.Item
        className="btn btn-light rounded-pill p-2 m-2 fw-normal d-flex align-items-end"
        to="/register"
        as={Link}
      >
        {t("register")}
      </Nav.Item>
    </>
  );
}

export default RegisterModal;
