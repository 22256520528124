import AuthWrapper from "../../layout/desktop/AuthWrapper";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Image, Modal } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ShowIcon from "../../assets/icons/show.svg";
import HideIcon from "../../assets/icons/hide.svg";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import UserContext from "../../services/user";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

function ForgotPasswordPage(params) {
  const { t } = useTranslation();
  const { loading, addMaster } = useContext(UserContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (values) => {
    let response = await addMaster(
      "user/req-forgot-password",
      values,
      "Forget password successfully send. Please check your registered email for link reset password"
    );
    if (!response?.err) {
      navigate("/");
    }
  };

  return (
    <AuthWrapper>
      <h4 className="mb-3">Forgot Password</h4>
      <h6 className="text-grey fw-light">
        We'll send you a link to change your password. Please enter your email
        address below.
      </h6>
      <form className="form my-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <Form.Control
            type="email"
            {...register("email")}
            className="control-input mt-2"
            placeholder={t("email_address")}
          />
          <small className="text-danger">{errors.email?.message}</small>
        </div>
        <Button
          variant="primary"
          className="text-white w-100 mt-1"
          type="submit"
        >
          Send
        </Button>
      </form>
    </AuthWrapper>
  );
}

export default ForgotPasswordPage;
