import React from "react";
import { Button, Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import RightArrow from "../../../assets/icons/rightArrow.svg";
import ItemGroup from "./itemGroup";
import { useTranslation } from "react-i18next";

function SectionGlobal(props) {
  const { t } = useTranslation();
  return (
    <Container className="section2">
      <div className="mb-5">
        <div className="d-flex align-items-center justify-content-between fs-5 mt-5 pb-4">
          <div className="fs-4 fw-bold w-75">{props?.type}</div>
          <Link
            className="text-primary text-decoration-none see-all-1"
            to="/local-experience/Best%20Seller"
          >
            {t("see_all")} <Image src={RightArrow} />
          </Link>
        </div>

        <ItemGroup data={props.data} />

        {/* <Row className={"overflow-card "+(props.data?.length>1&&("full"))}>
          {props.data &&
            props.data.map((item, index) => (
              <Col sm="12" md="6" lg="4" xl="3" key={index} className="mb-3">
                <ItemLocalExperience data={item} />
              </Col>
            ))}
        </Row> */}
        <Button
          as={Link}
          to="/local-experience/Best%20Seller"
          className="see-all-2 color2 w-100 text-center text-info"
          variant="light-info"
        >
          View all Local Experience
        </Button>
      </div>
      {props.type !== "Weekend Healing" && <hr></hr>}
    </Container>
  );
}

export default SectionGlobal;
