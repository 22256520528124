import React from "react";
import { Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import WorldIcon from "../../../assets/icons/world.svg";
import SampleTA from "../../../assets/images/partners/sampleTA.jpg";

function ItemTravelAssistance(props) {
  return (
    <Link
      to={`/travel-assistance/detail/${props?.data?._id}`}
      className="text-dark text-decoration-none"
    >
      <Card className="border-0 package">
        <Image className="card-img-top rounded-top" src={props?.data?.photoProfile? process.env.REACT_APP_FILE_URL+"/"+props?.data?.photoProfile : SampleTA} />
        <Card.Body className="d-flex flex-column border rounded-bottom">
          <div className="fw-light small text-muted">
            {props?.data?.location}
          </div>
          <div className="fw-bold fs-5">
            {props?.data?.firstName + " " + props?.data?.lastName}
          </div>
          <div className="mt-auto fw-light small text-muted d-flex align-items-start">
            <Image src={WorldIcon} />
            <div className="ps-2">
              {props?.data?.travelAssistance?.languages &&
                props?.data?.travelAssistance?.languages?.map((item, index) =>
                  index === props?.data?.travelAssistance?.languages.length - 1
                    ? item
                    : item + ", "
                )}
            </div>
          </div>
        </Card.Body>
      </Card>
    </Link>
  );
}

export default ItemTravelAssistance;
