import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import UserContext from "../../services/user";
import { SkeletonCustom } from "../../component/global/skeleton";
import DesktopWrapper from "../../layout/desktop/DesktopWrapper";
import { useSearchParams } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import ItemColabExperience from "../colabExperiences/desktop/components/item";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const CategoryPage = (props) => {
  //   const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { loading, addMaster } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    type: ["open trip", "private trip"],
    startPrice: 1,
    endPrice: 100000000,
    location: [],
    search: searchParams.get("search") ?? "",
    bannerId: searchParams.get("banner"),
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(30);

  const getData = async () => {
    const newData = await addMaster(
      `product/filter-products?page=${page}&limit=${pageLimit}`,
      filter
    );
    if (newData) {
      // console.log("Data Awal:", newData.data);
      const filteredData = newData.data.filter((item) =>
        item.productDetail.categories.includes("Sailing Komodo")
      );
      // console.log("Data Setelah Filter:", filteredData);
      setData(filteredData);
      setTotal(filteredData.length);
      // setData(newData.data);
      // setTotal(newData.total);
    }
  };

  useEffect(() => {
    if (filter?.type?.length <= 0) {
      filter.type = ["open trip", "private trip"];
    }
    getData();
  }, []);

  return (
    <DesktopWrapper>
      <Container>
        <div className="d-flex pb-3 pt-4 justify-content-between">
          <div className="mt-3 h5 fw-bold d-flex align-items-center row w-100 text-center">
            {/* {t("recommended_local_experience")} */}

            {/* <div className="col-md-8 text-md-start">
            {t("pigijo_collaboration")}
          </div>
          <div className="col-md-4 text-md-end">
            <img
              className="img-fluid "
              src={Logo}
              alt=""
              style={{ width: "250px", height: "45px" }}
            />
          </div> */}
          </div>
        </div>

        <Col sm="12" md="12" lg="12" xl="12">
          {loading ? (
            <SkeletonCustom height="100px" />
          ) : (
            <Row className="section2 align-items-center">
              {data &&
                data.map((item, index) => (
                  <Col
                    sm="12"
                    md="6"
                    lg="4"
                    xl="4"
                    className="mb-3"
                    key={index}
                  >
                    <ItemColabExperience data={item} />
                    {/* <GuideList data={item} /> */}
                  </Col>
                ))}
            </Row>
          )}
        </Col>
      </Container>
    </DesktopWrapper>
  );
};

export default CategoryPage;
