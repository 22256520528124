import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import LeftComponent from "./components/LeftComponent";
import Profile from "./components/Profile";
import Order from "./components/Order";
import OrderFlight from "./components/OrderFlight";
import OrderDetail from "./components/OrderDetail";
import OrderFlightDetail from "./components/OrderFlightDetail";

function ProfileDesktopPage() {
  const { pathname } = useLocation();

  return (
    <DesktopWrapper>
      <Container className="text-center">
        <Row className="my-5 text-start">
          <Col sm="12" md="12" lg="4" xl="4" className="mb-3">
            <LeftComponent />
          </Col>
          <Col sm="12" md="12" lg="8" xl="8">
            {pathname.split("/")[1] === "profile" && <Profile />}
            {pathname.split("/")[1] === "order" && !pathname.split("/")[2] && <Order />}
            {pathname.split("/")[1] === "order-flight" && !pathname.split("/")[2] && <OrderFlight />}
            {pathname.split("/")[1] === "order" && pathname.split("/")[2] && <OrderDetail />}
            {pathname.split("/")[1] === "order-flight" && pathname.split("/")[2] && <OrderFlightDetail />}
          </Col>
        </Row>
      </Container>
    </DesktopWrapper>
  );
}
export default ProfileDesktopPage;
