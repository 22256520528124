import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import DesktopWrapper from "../../../../layout/desktop/DesktopWrapper";
// import GuideProduct from "../../component/desktop/card/guideProduct";
// import GuideList from "../../../pages/additional/guide";
import { useParams } from "react-router-dom";

// import profile from "../../../assets/images/landingPage/hero.png";
import star from "../../../../assets/icons/star-icon.svg";
import starHalf from "../../../../assets/icons/star-half.svg";
import starLine from "../../../../assets/icons/star-line.svg";
// import verified from "../../assets/icons/verified.svg";
import orangeVerified from "../../../../assets/icons/orangeVerified.svg";
import location from "../../../../assets/icons/location.svg";
import announcement from "../../../../assets/icons/announcement.svg";
import jojo from "../../../../assets/auth/jojo-pantai.png";

function GuideDetails() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [rating, setRating] = useState(0);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(process.env.REACT_APP_API_GUIDE_ID);
  //       setProduct(response.data);
  //       setRating(response.data.rating);
  //     } catch (error) {
  //       console.error("Error fetching:", error);
  //     }
  //   };
  //   fetchData();
  // }, [id]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_GUIDE_ID}?id=${id}`)
      .then((response) => {
        setProduct(response.data);
        setRating(response.data.rating);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const renderStars = () => {
    const fullStars = Math.floor(rating); // Bintang penuh
    const hasHalfStar = rating % 1 !== 0; // Cek apakah ada setengah bintang

    const stars = [];
    // Tambahkan bintang penuh
    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <img
          key={i}
          src={star}
          alt="star"
          style={{ width: "15px", height: "14px" }}
          className="me-1"
        />
      );
    }
    // Tambahkan setengah bintang jika ada
    if (hasHalfStar) {
      stars.push(
        <img
          key="halfStar"
          src={starHalf}
          alt="halfStar"
          style={{ width: "15px", height: "14px" }}
          className="me-1"
        />
      );
    }
    return stars;
  };

  return (
    <DesktopWrapper>
      <Container className="mt-5">
        {product && (
          <div className="row">
            <div className="col-md-12 col-lg-4 text-center">
              <img
                src={product.profile}
                alt="Profile"
                className="rounded-circle mb-4 img-thumbnail"
                style={{ width: "150px", height: "150px" }}
              />
              <h6 className="mt-3 fw-bold">{product.name}</h6>
              <div className="mb-3 mt-3 rating"> {renderStars()}</div>
              {product.verif === "true" && (
                <p className="mt-4">
                  <img
                    src={orangeVerified}
                    alt=""
                    style={{ width: "17.37px", height: "16.79px" }}
                    className="me-1"
                  />
                  Tersertifikasi
                </p>
              )}
              <div>
                <img
                  src={location}
                  alt=""
                  style={{ width: "18px", height: "18px" }}
                  className="me-1"
                />
                {product.region}
              </div>
            </div>
            <div className="col-md-12 col-lg-8 mt-4">
              <h5 className="mb-4">Hai, saya {product.name}</h5>
              <h6 className="mb-3">
                Tunggu, {product.name} belum berbagi cerita tentang dirinya
              </h6>
              <div className="row mx-0 mb-4">
                <ul className="pills-wrapper p-0 mx-m1 my-m1">
                  {Object.values(product.category).map((category, index) => (
                    <li key={index} className="badge mx-1 my-1 bg-primary">
                      {category}
                    </li>
                  ))}
                </ul>
              </div>
              <p>
                <img
                  src={announcement}
                  alt=""
                  style={{ width: "30px", height: "25px" }}
                  className="me-2"
                />
                Berbicara dalam Bahasa {product.language.bahasa01} &{" "}
                {product.language.bahasa02}
              </p>
              <h6>Belum pernah melakukan pemanduan wisata</h6>
              <p>Rate sebagai pemandu mulai dari {product.price}</p>
              <a
                href=""
                target="_blank"
                class="btn w-100 w-lg-90 btn-outline-primary mt-3"
              >
                Jadikan Pemandumu
              </a>
            </div>
            <div className="content-guide my-5 ">
              <h5>Aktivitas milik {product.name}</h5>
              <p>
                Dapatkan pengalaman yang tak terlupakan dipandu oleh{" "}
                {product.name}
              </p>
              <div
                className="empty-state-wrapper border-1  d-flex align-items-center justify-content-center border w-auto rounded-4 border-dark-subtle"
                // style={{ maxWidth: "1200px", maxHeight: "300px" }}
              >
                {" "}
                {product.image ? (
                  <div
                    id="carouselExampleControls"
                    class="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div class="carousel-inner rounded">
                      <div class="carousel-item active">
                        <img
                          // src="https://images.unsplash.com/photo-1501785888041-af3ef285b470?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          // src={product.image}
                          src="https://file-investor.s3.ap-southeast-1.amazonaws.com/carosel/14.png"
                          class="d-block w-100 "
                          alt="..."
                        />
                      </div>
                      <div class="carousel-item">
                        <img
                          // src="https://images.unsplash.com/photo-1502657877623-f66bf489d236?q=80&w=1769&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          // src={product.image}
                          src="https://file-investor.s3.ap-southeast-1.amazonaws.com/carosel/18.png"
                          class="d-block w-100"
                          alt="..."
                        />
                      </div>
                      <div class="carousel-item">
                        <img
                          // src="https://images.unsplash.com/photo-1507525428034-b723cf961d3e?q=80&w=1773&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          // src={product.image}
                          src="https://file-investor.s3.ap-southeast-1.amazonaws.com/carosel/15.png"
                          class="d-block w-100"
                          alt="..."
                        />
                      </div>
                    </div>
                    <button
                      class="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                      class="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                ) : (
                  <div className="row img-empty">
                    <img
                      src={jojo}
                      alt=""
                      className="col-9 p-1 mx-0 img-fluid"
                      style={{ maxWidth: "370px", height: "auto" }}
                    />
                    <p className="d-flex col-3 align-items-center ms-0 p-0 fw-bold  justify-content-center text-md-center text-start ">
                      {product.activity}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Container>
    </DesktopWrapper>
  );
}

export default GuideDetails;
