import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import RightArrowIcon from "../../../assets/icons/rightArrow.svg";
import ReactSelect from "react-select";
import VisitorCard from "./components/visitor";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../../services/user";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import VisitorForm from "./components/visitorForm";
import { useTranslation } from "react-i18next";

function BookingFormDesktopPage() {
  const { t } = useTranslation();
  const { data, getMaster, payment } = useContext(UserContext);
  const [showPrice, setShowPrice] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [dataBefore, setDataBefore] = useState({});
  const [dataSalutation, setDataSalutation] = useState([
    {
      value: "Mr",
      label: "Mr",
    },
    {
      value: "Ms",
      label: "Ms",
    },
    {
      value: "Mrs",
      label: "Mrs",
    },
  ]);
  const [dataCountryCode, setDataCountryCode] = useState([
    {
      value: "62",
      label: "+62",
    },
  ]);
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: localStorage.getItem("title"),
      firstName: localStorage.getItem("firstName"),
      lastName: localStorage.getItem("lastName"),
      countryCode: localStorage.getItem("countryCode"),
      phoneNumber: localStorage.getItem("phone"),
      email: localStorage.getItem("email"),
    },
  });

  const onSubmit = async (values) => {
    values.dataBefore = dataBefore;
    const customer = values.customers?.map((item) => ({
      salutation: item.salutation,
      firstName: item.firstName,
      lastName: item.lastName,
      phone: item.countryCode + item.phone,
      email: item.email,
    }));
    values.customers = customer;
    values.details = dataDetail;
    const newData = await payment(values);
    if (newData) {
      navigate("/local-experience/payment/" + dataBefore?._id);
    }
  };

  const getData = async () => {
    const newData = await getMaster("product", data?._id);
    if (newData) {
      setDataDetail(newData.data);
    }
  };

  useEffect(() => {
    if (data?.qty) {
      let vistorCard = [];
      setDataBefore(data);
      for (let i = 1; i <= data?.qty; i++) {
        vistorCard.push({
          salutation: "",
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        });
      }
      setValue("customers", vistorCard);
      getData();
    } else {
      navigate(-1);
    }
  }, []);

  return (
    <DesktopWrapper>
      <Container className="detail-product py-4">
        <Row>
          <Col sm="12" md="12" lg="8" xl="8">
            <h4>{t("booking_form")}</h4>
            <Card className="border-0 shadow-sm mt-4">
              <Card.Body className="p-4">
                <h5>{t("customer_data")}</h5>
                <Row className="pt-3">
                  <Col sm="12" md="2" lg="2" xl="2" className="mb-2">
                    <Controller
                      control={control}
                      {...register("title")}
                      render={({ field: { onChange, value, ref } }) => (
                        <ReactSelect
                          ref={ref}
                          className="select"
                          classNamePrefix="select"
                          placeholder={t("title")}
                          isSearchable={false}
                          value={dataSalutation.find((c) => c.value === value)}
                          onChange={(val) => onChange(val.value)}
                          options={dataSalutation}
                        />
                      )}
                    />
                    <small className="text-danger">
                      {errors.title?.message}
                    </small>
                  </Col>
                  <Col sm="12" md="5" lg="5" xl="5" className="mb-2">
                    <Form.Control
                      {...register("firstName")}
                      className="control-input"
                      placeholder={t("first_name")}
                    />
                    <small className="text-danger">
                      {errors.firstName?.message}
                    </small>
                  </Col>
                  <Col sm="12" md="5" lg="5" xl="5" className="mb-2">
                    <Form.Control
                      {...register("lastName")}
                      className="control-input"
                      placeholder={t("last_name")}
                    />
                    <small className="text-danger">
                      {errors.lastName?.message}
                    </small>
                  </Col>
                  <Col sm="12" md="2" lg="2" xl="2" className="mb-2"></Col>
                  <Col sm="12" md="10" lg="10" xl="10" className="py-1 mb-2">
                    <small className="fw-light text-grey">
                      {t("as_on_id_card")}
                    </small>
                  </Col>
                </Row>
                <Row className="pt-4">
                  <Col sm="12" md="2" lg="2" xl="2" className="mb-2">
                    <Controller
                      control={control}
                      name="countryCode"
                      render={({ field: { onChange, value, ref } }) => (
                        <ReactSelect
                          ref={ref}
                          className="select"
                          classNamePrefix="select"
                          isSearchable={false}
                          placeholder="+xx"
                          value={dataCountryCode.find((c) => c.value === value)}
                          onChange={(val) => onChange(val.value)}
                          options={dataCountryCode}
                        />
                      )}
                    />
                    <small className="text-danger">
                      {errors.countryCode?.message}
                    </small>
                  </Col>
                  <Col sm="12" md="10" lg="10" xl="10" className="mb-2">
                    <Form.Control
                      {...register("phoneNumber")}
                      className="control-input"
                      placeholder={t("phone_number")}
                    />
                    <small className="text-danger">
                      {errors.phoneNumber?.message}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="12" lg="12" xl="12" className="mb-2">
                    <Form.Control
                      {...register("email")}
                      className="control-input"
                      placeholder={t("email_address")}
                    />
                    <small className="text-danger">
                      {errors.email?.message}
                    </small>
                  </Col>
                  <Col sm="12" md="12" lg="12" xl="12" className="py-1 mb-2">
                    <small className="fw-light text-grey">
                      {t("eticket_will_be_sent_to_this_email_address")}
                    </small>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="border-0 shadow-sm mt-4">
              <Card.Body className="p-4">
                <h5>{t("visitor_data")}</h5>
                <VisitorForm
                  {...{
                    control,
                    register,
                    errors,
                    setValue,
                    getValues,
                    dataSalutation,
                    dataCountryCode,
                  }}
                />
              </Card.Body>
            </Card>

            <div className="mt-3 text-end">
              <Button
                onClick={handleSubmit(onSubmit)}
                className="text-white px-4 py-2"
                variant="primary"
              >
                {t("continue_to_payment")}
              </Button>
            </div>
          </Col>
          <Col sm="12" md="12" lg="4" xl="4" className="mt-4">
            <h4>{t("order_summary")}</h4>
            <Card className="border-0 shadow-sm mt-4">
              <Card.Body className="p-3">
                <Image
                  src={
                    dataDetail?.generalInformation?.picture?.length > 0 &&
                    process.env.REACT_APP_FILE_URL +
                      "/" +
                      dataDetail?.generalInformation?.picture[0]
                  }
                  className="booking-image mb-3"
                />
                <div className="py-2">
                  {dataDetail?.generalInformation?.name}
                </div>
                <div className="d-flex align-items-center justify-content-between py-2">
                  <div className="fw-light">{t("price")}</div>
                  <div>
                    {dataDetail?.pricingDetail?.publishedPrice !==
                      dataDetail?.pricingDetail?.crossOutPrice && (
                      <span className="small text-decoration-line-through text-muted">
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(dataDetail?.pricingDetail?.crossOutPrice)}
                      </span>
                    )}{" "}
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(dataDetail?.pricingDetail?.publishedPrice)}{" "}
                    <span className="fw-light">/pax</span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between py-2">
                  <div className="fw-light">{t("date_of_departure")}</div>
                  <div>{moment(dataBefore?.startDate).format("LL")}</div>
                </div>
                <div className="d-flex align-items-center justify-content-between py-2">
                  <div className="fw-light">{t("trip_duration")}</div>
                  <div>{dataDetail?.pricingDetail?.duration} days</div>
                </div>
                <div className="d-flex align-items-center justify-content-between py-2">
                  <div className="d-flex mt-3 align-item-center justify-content-between w-100">
                    <div className="text-muted">{t("total_price")}</div>
                    <div className="text-primary fs-5 fw-bold d-flex align-items-between">
                      <div className="pe-2">
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(dataBefore?.totalPrice)}
                      </div>
                      <Image
                        src={RightArrowIcon}
                        style={{ transform: "rotate(90deg)" }}
                        role="button"
                        onClick={() => setShowPrice(true)}
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Modal
          show={showPrice}
          onHide={() => setShowPrice(false)}
          size="sm"
          centered
          className="bg-grey bg-opacity-75"
        >
          <Modal.Body>
            <div className="mb-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="text-muted">{t("price")} (x{dataBefore?.qty})</div>
                <div>
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  }).format(
                    dataDetail?.pricingDetail?.crossOutPrice * dataBefore?.qty
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="text-muted">{t("discount")}</div>
                <div>
                  -{" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  }).format(
                    (dataDetail?.pricingDetail?.crossOutPrice -
                      dataDetail?.pricingDetail?.publishedPrice) *
                      dataBefore?.qty
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="text-muted">
                  {t("wholesales")} (x{dataBefore?.qty})
                </div>
                <div>
                  -{" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  }).format(dataBefore?.wholesalesDiscount)}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="text-muted">Campaign (x{dataBefore?.qty})</div>
                <div>
                  -{" "}
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  }).format(dataBefore?.campaignDiscount)}
                </div>
              </div>
              <hr></hr>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-bold">{t("total_price")}</div>
                <div className="fw-bold">
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  }).format(dataBefore?.totalPrice)}
                </div>
              </div>
            </div>
            <Button
              className="text-white w-100"
              onClick={() => setShowPrice(false)}
            >
              Close
            </Button>
          </Modal.Body>
        </Modal>
      </Container>
    </DesktopWrapper>
  );
}
export default BookingFormDesktopPage;
