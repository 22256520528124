import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../services/auth";

const PrivateRoute = ({ children }) => {
  const { payload } = useContext(AuthContext);

  const token = payload?.accessToken?.token
    ? payload?.accessToken?.token
    : localStorage.getItem("accessToken");

  if (!token) {
    return <Navigate to="/" replace />;
  }

  return children ? children : <Outlet />;
};

export default PrivateRoute;
