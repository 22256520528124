import React, { useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Card, Form, InputGroup } from "react-bootstrap";
import FooterNavigation from "../footerNavigation";
import { useForm } from "react-hook-form";
import UserContext from "../../../../../services/user";

const schema = yup
  .object({
    minimumOrderDay: yup.string().required("Minimum Order Day is required"),
    cancelationPolicy: yup.string().required("Cancelation Policy is required"),
  })
  .required();

function Step7(params) {
  const { data, registerForm } = useContext(UserContext);
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitStep = async (values) => {
    const newData = Object.assign(data, values);
    const response = await registerForm(newData);
    if (response) {
      params.submit();
    }
  };

  return (
    <Card className="card-step border-0 shadow-sm">
      <Card.Body>
        <div className="pb-4">
          <div className="fw-bold pb-3">Minimum Order Day</div>
          <div className="text-muted small">
            Set a minimum of booking days before the trip is made.
          </div>
        </div>
        <div className="mb-3">
          <InputGroup>
            <Form.Control
              placeholder="e.g. 1"
              className="control-input"
              {...register("minimumOrderDay")}
            />
            <InputGroup.Text id="basic-addon2">day</InputGroup.Text>
          </InputGroup>
          <small className="text-danger">
            {errors.minimumOrderDay?.message}
          </small>
        </div>
        <div className="radio-group mb-3 fw-bold">
          <div className="pb-3">Cancellation Policy</div>
          <Form.Check
            label="No Cancellation"
            name="cancellation_policy"
            type="radio"
            id={`radio-1`}
            value="The customer's money is not returned at all if it has been booked &
            paid."
            {...register("cancelationPolicy")}
          />
          <div className="ps-4 ms-3 pb-3 fw-light">
            The customer's money is not returned at all if it has been booked &
            paid.
          </div>
          <Form.Check
            label="Free Cancellation"
            name="cancellation_policy"
            type="radio"
            id={`radio-2`}
            value="The customer's money will be fully refunded (less bank
            administration fees)."
            {...register("cancelationPolicy")}
          />
          <div className="ps-4 ms-3 pb-3 fw-light">
            The customer's money will be fully refunded (less bank
            administration fees).
          </div>
          <Form.Check
            label="Maximum Cancellation D-3"
            name="cancellation_policy"
            type="radio"
            id={`radio-3`}
            value="The money that will be received by the tour operator is 50% of the
          price."
            {...register("cancelationPolicy")}
          />
          <div className="ps-4 ms-3 pb-3 fw-light">
            The money that will be received by the tour operator is 50% of the
            price.
          </div>
          <small className="text-danger">
            {errors.cancelationPolicy?.message}
          </small>
        </div>
        <FooterNavigation
          totalSteps={params.totalSteps}
          currentStep={params.currentStep}
          previousStep={params.previousStep}
          nextStep={handleSubmit(submitStep)}
          disabled={false}
          submit={params.submit}
        />
      </Card.Body>
    </Card>
  );
}

export default Step7;
