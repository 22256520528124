import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PigijoLogo from "../../assets/Pigijo.svg";
import FacebookIcon from "../../assets/icons/social-media/facebook.svg";
import InstagramIcon from "../../assets/icons/social-media/instagram.svg";
import YoutubeIcon from "../../assets/icons/social-media/youtube.svg";
import WhatsappIcon from "../../assets/icons/social-media/whatsapp.svg";
import TwitterIcon from "../../assets/icons/social-media/twitter.svg";
import TiktokIcon from "../../assets/icons/social-media/tiktok.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";
import CertifiedImage from "../../assets/images/certified.png";
import { useTranslation } from "react-i18next";

function FooterDesktop() {
  const { t } = useTranslation();
  return (
    <footer className="pt-5 text-center text-lg-start fw-light text-secondary">
      <section className="footer-main">
        <Container className="text-start text-md-start mt-5">
          <Row className="mt-3 justify-content-end">
            <Col md="3" lg="3" xl="3" className="mb-4">
              <Link to="/">
                <Image src={PigijoLogo} />
              </Link>
            </Col>
            <Col xs="6" sm="6" md="3" lg="3" xl="3" className="mb-4">
              <h6 className="fs-18 fw-semi-bold mb-4 text-dark">
                {t("products")}
              </h6>
              <div className="mb-3">
                <Link
                  to="/local-experience"
                  className="text-dark text-decoration-none"
                >
                  Local Experience
                </Link>
              </div>
              {/* <div className="mb-3">
                <Link
                  to="/transportation"
                  className="text-dark text-decoration-none"
                >
                  Transportation
                </Link>
              </div> */}
              <div className="mb-3">
                <a
                  href="https://blog.pigijo.com"
                  className="text-dark text-decoration-none"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </div>
              <div className="mb-3">
                {/* <Link to="/guide" className="text-dark text-decoration-none">
                  Guide
                </Link> */}
              </div>
            </Col>
            <Col xs="6" sm="6" md="3" lg="3" xl="3" className="mb-4">
              <h6 className="fs-18 fw-semi-bold mb-4 text-dark">
                {t("information")}
              </h6>
              <div className="mb-3">
                <Link to="/about-us" className="text-dark text-decoration-none">
                  {t("about_us")}
                </Link>
              </div>
              <div className="mb-3">
                <Link to="/investor" className="text-dark text-decoration-none">
                  {t("investor")}
                </Link>
              </div>
              <div className="mb-3">
                <Link
                  to="/terms-and-conditions"
                  className="text-dark text-decoration-none"
                >
                  {t("terms_conditions")}
                </Link>
              </div>
              <div className="mb-3">
                <Link
                  to="/privacy-policy"
                  className="text-dark text-decoration-none"
                >
                  {t("privacy_policy")}
                </Link>
              </div>
            </Col>
            <Col md="3" lg="3" xl="3" className="mb-4">
              <h6 className="fs-18 fw-semi-bold mb-4 text-dark">
                {t("contact_us")}
              </h6>
              <table>
                <tbody>
                  <tr>
                    <td className="pb-3">Mon - Fri</td>
                    <td className="pb-3">: 08.30 AM - 06.00 PM</td>
                  </tr>
                  <tr>
                    <td className="pb-3">Whatsapp</td>
                    <td className="pb-3">
                      :{" "}
                      <a
                        href="https://wa.me/6282246598802"
                        target="_blank"
                        rel="noreferrer"
                        className="text-dark text-decoration-none"
                      >
                        (+62) 822 4659 8802
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-3">Mail</td>
                    <td className="pb-3">
                      :{" "}
                      <a
                        href="mailto:info@pigijo.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-dark text-decoration-none"
                      >
                        info@pigijo.com
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col md="3" lg="3" xl="3" className="mb-4">
              <h6 className="fs-18 fw-semi-bold mb-4 text-dark">
                {t("find_us")}
              </h6>
              <div className="d-flex">
                <a href="https://facebook.com/" className="pe-3">
                  <Image src={FacebookIcon} />
                </a>
                <a href="https://instagram.com/" className="pe-3">
                  <Image src={InstagramIcon} />
                </a>
                <a href="https://youtube.com/" className="pe-3">
                  <Image src={YoutubeIcon} />
                </a>
              </div>
              <div className="d-flex">
                <a href="https://whatsapp.com/" className="pe-3">
                  <Image src={WhatsappIcon} />
                </a>
                <a href="https://twitter.com/" className="pe-3">
                  <Image src={TwitterIcon} />
                </a>
                <a href="https://tiktok.com/" className="pe-3">
                  <Image src={TiktokIcon} />
                </a>
              </div>
            </Col>
            <Col md="6" lg="6" xl="6" className="mb-4">
              <h6 className="fs-18 fw-semi-bold mb-4 text-dark">
                {t("our_office")}
              </h6>
              <a
                href="https://maps.com/"
                className="text-decoration-none text-dark lh-base"
              >
                Lt. 9 Unit B, Satrio Tower Building, Jl. Prof. DR. Satrio No.5,
                RT.7/RW.2, Kuningan, Jakarta, Kota Jakarta Selatan, Daerah
                Khusus Ibukota Jakarta 12950, Indonesia
              </a>
            </Col>
            <Col md="3" lg="3" xl="3" className="mb-4">
              <h6 className="fs-18 fw-semi-bold mb-4 text-dark">
                ISO Certified
              </h6>
              <div>
                <Image src={CertifiedImage} />
                <a
                  href="https://m.certipedia.com/?id=9000016302&locale=en"
                  className="mt-2 text-primary text-decoration-none d-block"
                >
                  Read More <Image src={RightArrow} />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="text-center p-4 lh-base">
        © 2021-2022 Pigijo is a trademark of PT Tourindo Guide Indonesia Tbk
      </div>
    </footer>
  );
}

export default FooterDesktop;
