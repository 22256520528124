import React, { useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Card, Form } from "react-bootstrap";
import ReactSelect from "react-select";
import FooterNavigation from "../footerNavigation";
import { Controller, useForm } from "react-hook-form";
import UserContext from "../../../../../services/user";

const schema = yup
  .object({
    personalAccountData: yup.object().shape({
      beneficiaryAccountName: yup.string().required("Beneficiary Account Name is required"),
      accountNumber: yup.string().required("Account Number is required"),
      bankName: yup.string().required("Bank Name is required"),
      bankBranch: yup.string().required("Bank Branch is required"),
      cityBankBranch: yup.string().required("City Bank Branch is required"),
    }),
  })
  .required();

function Step3(params) {
  const { data, registerForm } = useContext(UserContext);
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitStep = async (values) => {
    const newData = Object.assign(data, values);
    const response = await registerForm(newData);
    if (response) {
      params.nextStep();
    }
  };

  return (
    <Card className="card-step border-0 shadow-sm">
      <Card.Body>
        <div className="pb-3">Personal Account Data</div>
        <div className="mb-3">
          <Form.Control
            className="control-input mb-3"
            placeholder="Beneficiary Account Name"
            {...register("personalAccountData.beneficiaryAccountName")}
          />
          <small className="text-danger">
            {errors.personalAccountData?.beneficiaryAccountName?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            className="control-input mb-3"
            placeholder="Account Number"
            {...register("personalAccountData.accountNumber")}
          />
          <small className="text-danger">
            {errors.personalAccountData?.accountNumber?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            className="control-input mb-3"
            placeholder="Bank Name"
            {...register("personalAccountData.bankName")}
          />
          <small className="text-danger">
            {errors.personalAccountData?.bankName?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            className="control-input mb-3"
            placeholder="Bank Branch"
            {...register("personalAccountData.bankBranch")}
          />
          <small className="text-danger">
            {errors.personalAccountData?.bankBranch?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            className="control-input mb-3"
            placeholder="City Bank Branch"
            {...register("personalAccountData.cityBankBranch")}
          />
          <small className="text-danger">
            {errors.personalAccountData?.cityBankBranch?.message}
          </small>
        </div>
        <FooterNavigation
          totalSteps={params.totalSteps}
          currentStep={params.currentStep}
          previousStep={params.previousStep}
          nextStep={handleSubmit(submitStep)}
          disabled={false}
          submit={params.submit}
        />
      </Card.Body>
    </Card>
  );
}

export default Step3;
