import React, { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Card, Form } from "react-bootstrap";
import ReactSelect from "react-select";
import FooterNavigation from "../footerNavigation";
import { Controller, useForm } from "react-hook-form";
import UserContext from "../../../../../services/user";

const schema = yup
  .object({
    address: yup.string().required("Address is required"),
    village: yup.string().required("Village is required"),
    districts: yup.string().required("Districts is required"),
    city: yup.string().required("City is required"),
    province: yup.string().required("Province is required"),
    postalCode: yup.string().required("Postal Code is required"),
  })
  .required();

function Step2(params) {
  const { data, registerForm, getAllMaster } = useContext(UserContext);
  const [dataCity, setDataCity] = useState([]);
  const [dataDistricts, setDataDistricts] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitStep = async (values) => {
    const newData = Object.assign(data, values);
    const response = await registerForm(newData);
    if (response) {
      params.nextStep();
    }
  };

  const getDataCity = async (province_id) => {
    const newData = await getAllMaster(
      `address/city?provinceId=${province_id}`
    );
    if (newData) {
      let dataCitys = [
        {
          value: "",
          label: "- Select City -",
        },
      ];

      dataCitys.push(
        ...newData?.data?.map((item) => ({
          value: item.city_id,
          label: item.type+" "+item.city_name,
        }))
      );

      setDataCity(dataCitys);
    }
  };

  const getDataDistricts = async (city_id) => {
    const newData = await getAllMaster(`address/subdistrict?cityId=${city_id}`);
    if (newData) {
      let dataDistrictss = [
        {
          value: "",
          label: "- Select Districts -",
        },
      ];

      dataDistrictss.push(
        ...newData?.data?.map((item) => ({
          value: item.subdistrict_id,
          label: item.subdistrict_name,
        }))
      );

      setDataDistricts(dataDistrictss);
    }
  };

  useEffect(() => {
    watch((value, { name, type }) => {
      if (name === "province" && type === "change") {
        getValues("city") &&
          setValue("city", "");
        getValues("districts") &&
          setValue("districts", "");
        setDataCity([
          {
            value: "",
            label: "- Select City -",
          },
        ]);
        setDataDistricts([
          {
            value: "",
            label: "- Select Districts -",
          },
        ]);
        getDataCity(value?.province);
      } else if (name === "city" && type === "change") {
        getValues("districts") &&
          setValue("districts", "");
        setDataDistricts([
          {
            value: "",
            label: "- Select Districts -",
          },
        ]);
        getDataDistricts(value?.city);
      }
    });
  }, [watch]);

  return (
    <Card className="card-step border-0 shadow-sm">
      <Card.Body>
        <div className="pb-3">Domicile Address</div>
        <div className="mb-3">
          <Form.Control
            as="textarea"
            className="control-input"
            placeholder="Address"
            {...register("address")}
          />
          <small className="text-danger">{errors.address?.message}</small>
        </div>
        <div className="mb-3">
          <Form.Control
            className="control-input"
            placeholder="Village"
            {...register("village")}
          />
          <small className="text-danger">{errors.village?.message}</small>
        </div>
        <div className="mb-3">
          <Controller
            control={control}
            name="province"
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                ref={ref}
                className="select"
                classNamePrefix="select"
                isSearchable={true}
                placeholder="Province"
                value={params.dataProvince.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                options={params.dataProvince}
              />
            )}
          />
          <small className="text-danger">{errors.province?.message}</small>
        </div>
        <div className="mb-3">
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                ref={ref}
                className="select"
                classNamePrefix="select"
                isSearchable={true}
                placeholder="City/Regency"
                value={dataCity.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                options={dataCity}
              />
            )}
          />
          <small className="text-danger">{errors.city?.message}</small>
        </div>
        <div className="mb-3">
          <Controller
            control={control}
            name="districts"
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                ref={ref}
                className="select"
                classNamePrefix="select"
                isSearchable={true}
                placeholder="Districts"
                value={dataDistricts.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                options={dataDistricts}
              />
            )}
          />
          <small className="text-danger">
            {errors.districts?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            type="number"
            className="control-input"
            placeholder="Postal Code"
            {...register("postalCode")}
          />
          <small className="text-danger">{errors.postalCode?.message}</small>
        </div>
        <FooterNavigation
          totalSteps={params.totalSteps}
          currentStep={params.currentStep}
          previousStep={params.previousStep}
          nextStep={handleSubmit(submitStep)}
          disabled={false}
          submit={params.submit}
        />
      </Card.Body>
    </Card>
  );
}

export default Step2;
