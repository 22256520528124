import React, { useContext, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Card, Form } from "react-bootstrap";
import ReactSelect from "react-select";
import FooterNavigation from "../footerNavigation";
import { useForm } from "react-hook-form";
import UserContext from "../../../../../services/user";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    companyPersonResponsible: yup.object().shape({
      fullName: yup.string().required("Full Name is required"),
      positionInCompany: yup.string().required("Position is required"),
      phoneNumber: yup.string().required("Phone Number is required"),
      email: yup
        .string()
        .email("Email must be a valid email")
        .required("Email is required"),
    }),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    cPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

function Step3(params) {
  const { t } = useTranslation();
  const { data, registerForm } = useContext(UserContext);
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitStep = async (values) => {
    const newData = Object.assign(data, values);
    const response = await registerForm(newData);
    if (response) {
      params.nextStep();
    }
  };

  return (
    <Card className="card-step border-0 shadow-sm">
      <Card.Body>
        <div className="pb-3">Person Responsible</div>
        <div className="mb-3">
          <Form.Control
            className="control-input mb-3"
            placeholder="Full Name"
            {...register("companyPersonResponsible.fullName")}
          />
          <small className="text-danger">
            {errors.companyPersonResponsible?.fullName?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            className="control-input mb-3"
            placeholder="Position in the Company"
            {...register("companyPersonResponsible.positionInCompany")}
          />
          <small className="text-danger">
            {errors.companyPersonResponsible?.positionInCompany?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            className="control-input mb-3"
            placeholder={t("phone_number")}
            {...register("companyPersonResponsible.phoneNumber")}
          />
          <small className="text-danger">
            {errors.companyPersonResponsible?.phoneNumber?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            type="email"
            className="control-input mb-3"
            placeholder={t("email_address")}
            {...register("companyPersonResponsible.email")}
          />
          <small className="text-danger">
            {errors.companyPersonResponsible?.email?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            type="password"
            className="control-input"
            placeholder="Password"
            {...register("password")}
          />
          <small className="text-danger">{errors.password?.message}</small>
        </div>
        <div className="mb-3">
          <Form.Control
            type="password"
            className="control-input"
            placeholder="Retype Password"
            {...register("cPassword")}
          />
          <small className="text-danger">{errors.cPassword?.message}</small>
        </div>
        <FooterNavigation
          totalSteps={params.totalSteps}
          currentStep={params.currentStep}
          previousStep={params.previousStep}
          nextStep={handleSubmit(submitStep)}
          disabled={false}
          submit={params.submit}
        />
      </Card.Body>
    </Card>
  );
}

export default Step3;
