import { Button } from "react-bootstrap";

function FooterNavigation(params) {
  return (
    <div className="mt-4 d-flex align-items-center justify-content-between">
      {params.currentStep !== 1 ? (
        <Button
          className="btn-navigate"
          variant="outline-primary"
          onClick={params.previousStep}
        >
          Back
        </Button>
      ) : (
        <div></div>
      )}
      <Button
        className="text-white btn-navigate"
        variant="primary"
        onClick={()=>params.nextStep()}
        disabled={params.disabled}
      >
        Continue
      </Button>
    </div>
  );
}

export default FooterNavigation;
