import React from "react";
import { BrowserView } from "react-device-detect";
import PartnerChooseDesktopPage from "./desktop/partnerChoose";

function PartnerChoosePage() {
  return (
    // <BrowserView>
      <PartnerChooseDesktopPage />
    // </BrowserView>
  );
}
export default PartnerChoosePage;
