import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";

export default function VisitorForm({
  control,
  register,
  error,
  setValue,
  getValues,
  dataCountryCode,
  dataSalutation,
}) {
  const { t } = useTranslation();
  const { fields, remove, insert, replace } = useFieldArray({
    control,
    name: "customers",
  });

  const changeSameCustomer = (data) => {
    if (data.target.checked) {
      let newData = {
        salutation: getValues("title"),
        firstName: getValues("firstName"),
        lastName: getValues("lastName"),
        countryCode: getValues("countryCode"),
        email: getValues("email"),
        phone: getValues("phoneNumber"),
      };
      setValue("customers.0", newData);
    }
  };

  return (
    <>
      {fields.map((item, index) => (
        <div key={index}>
          <div className="d-flex align-items-center justify-content-between bg-primary-2 p-2 rounded mb-2 mt-4">
            <div className="text-primary">{t("guest")} {index + 1}: {t("adult")}</div>
            {index === 0 && (
              <div className="d-flex align-items-center">
                <div className="pe-3 fw-light small">
                  {t("same_with_the_customer")}
                </div>
                <Form.Check
                  type="switch"
                  className="custom-switch xl"
                  onChange={changeSameCustomer}
                />
              </div>
            )}
          </div>
          <Row className="pt-3">
            <Col sm="12" md="2" lg="2" xl="2" className="mb-2">
              <Controller
                control={control}
                name={`customers.${index}.salutation`}
                render={({ field: { onChange, value, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    className="select"
                    classNamePrefix="select"
                    placeholder={t("title")}
                    isSearchable={false}
                    value={dataSalutation.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                    options={dataSalutation}
                  />
                )}
              />
            </Col>
            <Col sm="12" md="5" lg="5" xl="5" className="mb-2">
              <Form.Control
                className="control-input"
                placeholder={t("first_name")}
                defaultValue={`${item.firstName}`}
                {...register(`customers[${index}].firstName`)}
              />
            </Col>
            <Col sm="12" md="5" lg="5" xl="5" className="mb-2">
              <Form.Control
                className="control-input"
                placeholder={t("last_name")}
                defaultValue={`${item.lastName}`}
                {...register(`customers[${index}].lastName`)}
              />
            </Col>
            <Col sm="12" md="2" lg="2" xl="2"></Col>
            <Col sm="12" md="10" lg="10" xl="10" className="py-1 mb-2">
              <small className="fw-light text-grey">{t("as_on_id_card")}</small>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col sm="12" md="2" lg="2" xl="2" className="mb-2">
              <Controller
                control={control}
                name={`customers.${index}.countryCode`}
                render={({ field: { onChange, value, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    className="select"
                    classNamePrefix="select"
                    isSearchable={false}
                    placeholder="+xx"
                    value={dataCountryCode.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                    options={dataCountryCode}
                  />
                )}
              />
            </Col>
            <Col sm="12" md="10" lg="10" xl="10" className="mb-2">
              <Form.Control
                className="control-input"
                placeholder={t("phone_number")}
                defaultValue={`${item.phone}`}
                {...register(`customers[${index}].phone`)}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12" xl="12" className="mb-2">
              <Form.Control
                className="control-input"
                placeholder={t("email_address")}
                defaultValue={`${item.email}`}
                {...register(`customers[${index}].email`)}
              />
            </Col>
            <Col sm="12" md="12" lg="12" xl="12" className="py-1">
              <small className="fw-light text-grey">
                {t("eticket_will_be_sent_to_this_email_address")}
              </small>
            </Col>
          </Row>
        </div>
      ))}
    </>
  );
}
