import { useContext, useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import id from "date-fns/locale/id";
import ClimbingIcon from "../../../assets/images/hero/climbing.svg";
import PlaneIcon from "../../../assets/images/hero/plane.svg";
import PinIcon from "../../../assets/images/hero/pin.svg";
import GuestIcon from "../../../assets/images/hero/guest.svg";
// import TadaImage from "../../../assets/images/hero/tada3.png";
import TadaImage from "../../../assets/images/hero/tada4.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchIcon from "../../..//assets/icons/search.svg";
import DepartureIcon from "../../..//assets/icons/departure.svg";
import ArrivalIcon from "../../..//assets/icons/arrival.svg";
import CalenderInIcon from "../../..//assets/icons/calenderIn.svg";
import CalenderOutIcon from "../../..//assets/icons/calenderOut.svg";
import CityIcon from "../../..//assets/icons/city.svg";
import CityNotFound from "../../..//assets/icons/cityNotFound.png";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import UserContext from "../../../services/user";
import { toast } from "react-toastify";
import moment from "moment";
import { useTranslation } from "react-i18next";
registerLocale("id", id);

function HeroSection(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [active, setActive] = useState(1);
  const { getAllMaster } = useContext(UserContext);
  const [departuredSelected, setDeparturedSelected] = useState(null);
  const [destinationSelected, setDestinationSelected] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [cabinClass, setCabinClass] = useState("economy");
  const [search, setSearch] = useState("");
  const [returnValue, setReturnValue] = useState(false);
  const [qtyAdult, setQtyAdult] = useState(1);
  const [qtyChild, setQtyChild] = useState(0);
  const [qtyInfant, setQtyInfant] = useState(0);
  const [passanger, setPassanger] = useState(1);
  const [text, setText] = useState("");
  const [searchParams] = useSearchParams();
  // const [city, setCity] = useState([]);
  const [modal, setModal] = useState({
    open: false,
    type: "",
  });
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const placeholders = [
    "Sailing Komodo - Labuan Bajo",
    "Lembah Purba",
    "Kepulauan Seribu",
    "Lombok",
    "Banda Neira",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPlaceholderIndex(
        (prevIndex) => (prevIndex + 1) % placeholders.length
      );
    }, 3000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [placeholders.length]);
  const searchAction = (evt) => {
    if (evt?.key === "Enter") {
      navigate(`/local-experience?search=${evt?.target?.value}`);
      window.location.reload();
    } else {
      setSearch(evt?.target?.value);
    }
  };

  // const preferredLocations = [
  //   "Jakarta",
  //   "Denpasar-Bali",
  //   "Yogyakarta",
  //   "Labuan Bajo",
  //   "Surabaya",
  //   "Medan",
  // ];

  const preferredLocations = [
    {
      location: "Jakarta, Indonesia",
      code: "CGK",
      name: "Soekarno Hatta",
    },
    {
      location: "Denpasar-Bali, Indonesia",
      code: "DPS",
      name: "Ngurah Rai",
    },
    {
      location: "Yogyakarta, Indonesia",
      code: "YIA",
      name: "Yogyakarta Kulon Progo",
    },
    {
      location: "Labuan Bajo, Indonesia",
      code: "LBJ",
      name: "Komodo",
    },
    {
      location: "Surabaya, Indonesia",
      code: "SUB",
      name: "Juanda",
    },
    {
      location: "Medan, Indonesia",
      code: "KNO",
      name: "Kuala Namu",
    },
  ];

  const [filteredLocations, setFilteredLocations] = useState([]);

  useEffect(() => {
    setFilteredLocations(preferredLocations);
  }, []);

  useEffect(() => {
    if (search !== "") {
      getDataAirPort();
    } else {
      setFilteredLocations(preferredLocations);
    }
  }, [search]);

  // const sortedCity = [...city].sort((a, b) => {
  //   const getLocationIndex = (location) => {
  //     const cityName = location.split(",")[0];
  //     return preferredLocations.indexOf(cityName);
  //   };

  //   const aIndex = getLocationIndex(a.location);
  //   const bIndex = getLocationIndex(b.location);

  //   if (aIndex === -1) return 1;
  //   if (bIndex === -1) return -1;

  //   return aIndex - bIndex;
  // });

  const submitLocalExperience = () => {
    navigate(`/local-experience?search=${search}`);
    window.location.reload();
  };

  const handleClose = () => {
    setSearch("");
    setModal({ open: false, type: "" });
  };

  const onChangeQty = (type, pessanger) => {
    if (pessanger === "adult") {
      if (type === "minus" && qtyAdult >= 2) {
        setQtyAdult(qtyAdult - 1);
      } else if (type === "plus" && qtyAdult < 10) {
        setQtyAdult(qtyAdult + 1);
      }
    } else if (pessanger === "child") {
      if (type === "minus" && qtyChild >= 1) {
        setQtyChild(qtyChild - 1);
      } else if (type === "plus" && qtyChild < 10) {
        setQtyChild(qtyChild + 1);
      }
    } else if (pessanger === "infant") {
      if (type === "minus" && qtyInfant >= 1) {
        setQtyInfant(qtyInfant - 1);
      } else if (type === "plus" && qtyInfant < 10) {
        setQtyInfant(qtyInfant + 1);
      }
    }
  };

  // const getDataAirPort = async () => {
  //   const newData = await getAllMaster(`airport`, { search: search });
  //   if (newData) {
  //     setCity(newData?.data);
  //   }
  // };

  const getDataAirPort = async () => {
    const newData = await getAllMaster(`airport`, { search: search });
    if (newData) {
      setFilteredLocations(newData?.data);
    } else {
      setFilteredLocations([]);
    }
  };

  const getDataAirPortSearch = async (src, type) => {
    const newData = await getAllMaster(`airport`, { search: src });
    if (newData) {
      let dataSelect = newData?.data?.length > 0 && newData?.data?.[0];
      if (dataSelect) {
        if (type === "departure") {
          setDeparturedSelected(dataSelect);
        } else {
          setDestinationSelected(dataSelect);
        }
      }
    }
  };

  //1
  const handleCityClick = (item) => {
    if (modal.type === "departure") {
      if (
        destinationSelected &&
        item.location === destinationSelected.location
      ) {
        toast.error("Departure cannot be the same as destination");
      } else {
        setDeparturedSelected(item);
        handleClose();
      }
    } else {
      if (departuredSelected && item.location === departuredSelected.location) {
        toast.error("Destination cannot be the same as departure");
      } else {
        setDestinationSelected(item);
        handleClose();
      }
    }
  };

  useEffect(() => {
    if (props?.active) {
      setActive(props?.active);
      if (props?.active === 1) {
        setText(searchParams.get("search"));
      } else if (props?.active === 2) {
        getDataAirPortSearch(searchParams.get("departure_city"), "departure");
        getDataAirPortSearch(
          searchParams.get("destination_city"),
          "destination"
        );
        setStartDate(moment(searchParams.get("startDate")).toDate());
        searchParams.get("endDate") &&
          setEndDate(moment(searchParams.get("endDate")).toDate());
        setCabinClass(searchParams.get("class"));
        setQtyAdult(parseInt(searchParams.get("adult")));
        setQtyChild(parseInt(searchParams.get("child")));
        setQtyInfant(parseInt(searchParams.get("infant")));
        setPassanger(
          parseInt(searchParams.get("adult")) +
            parseInt(searchParams.get("child")) +
            parseInt(searchParams.get("infant"))
        );
      }
    }
  }, [props?.active]);

  // useEffect(() => {
  //   if (modal.type === "departure" || modal.type === "destination") {
  //     getDataAirPort();
  //   }
  // }, [modal, search]);

  return (
    <div className={"hero2 " + (active == 2 ? "big-space" : "")}>
      <div class="container position-relative">
        <div className="img"></div>
        <div className="hi">
          <h4 className="fw-bold">Hi, Kawanjo!</h4>
          <h5>Let’s Discover a New Adventure!</h5>
        </div>
        <div className="pigijo-icon mb-1">
          <Image src={TadaImage} />
        </div>
        <div className="card-hero">
          <Tabs
            id="controlled-tab"
            activeKey={active}
            onSelect={(k) => setActive(k)}
          >
            <Tab
              eventKey="1"
              title={
                <div className="d-flex align-items-center">
                  <Image className="me-2" src={ClimbingIcon} />
                  <div>Local Experience</div>
                </div>
              }
            >
              <div className="search-flight new">
                <fieldset className="w-100 rounded">
                  <legend>{t("search")}</legend>
                  <div className="d-flex align-items-center">
                    <div className="bg-light border-0 pe-2">
                      <Image src={PinIcon} />
                    </div>
                    <div className="border-0 fw-light text-center w-100">
                      <Form.Control
                        className="border-0 fw-light"
                        // placeholder={t("search_trip")}
                        placeholder={placeholders[currentPlaceholderIndex]}
                        onKeyUp={searchAction}
                        defaultValue={text}
                      />
                    </div>
                    <div className="border-0 fw-light text-center">
                      <Button
                        className="text-white"
                        onClick={submitLocalExperience}
                      >
                        {t("search")}
                      </Button>
                    </div>
                  </div>
                </fieldset>
              </div>
            </Tab>
            <Tab
              eventKey="2"
              title={
                <div className="d-flex align-items-center">
                  <Image className="me-2" src={PlaneIcon} />
                  <div>Flight</div>
                </div>
              }
            >
              <div className="text-end">
                <div className="d-flex mt-5 search-flight">
                  <fieldset
                    className="w-100"
                    onClick={() =>
                      setModal({
                        type: "departure",
                        open: true,
                      })
                    }
                  >
                    <legend>From</legend>
                    <div className="d-flex align-items-center">
                      <div className="bg-light border-0 pe-2">
                        <Image src={DepartureIcon} />
                      </div>
                      <div className="border-0 fw-light text-center">
                        {departuredSelected ? (
                          <div className="fw-normal">
                            {departuredSelected?.name +
                              " (" +
                              departuredSelected?.code +
                              ")"}
                          </div>
                        ) : (
                          t("choose_departure")
                        )}
                      </div>
                    </div>
                  </fieldset>
                  <fieldset
                    className="w-100"
                    onClick={() =>
                      setModal({
                        type: "destination",
                        open: true,
                      })
                    }
                  >
                    <legend>To</legend>
                    <div className="d-flex align-items-center">
                      <div className="bg-light border-0 pe-2">
                        <Image src={ArrivalIcon} />
                      </div>
                      <div className="border-0 fw-light text-center">
                        {destinationSelected ? (
                          <div className="fw-normal">
                            {destinationSelected?.name +
                              " (" +
                              destinationSelected?.code +
                              ")"}
                          </div>
                        ) : (
                          t("choose_destination")
                        )}
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="w-100">
                    <legend>Departure</legend>
                    <div
                      className="d-flex align-items-center"
                      onClick={() =>
                        setModal({
                          type: "startDate",
                          open: true,
                        })
                      }
                    >
                      <div className="bg-light border-0 pe-2">
                        <Image src={CalenderInIcon} />
                      </div>
                      <div className="border-0 fw-light text-center">
                        {startDate ? (
                          <div className="fw-normal">
                            {moment(startDate).format("DD MMM YYYY")}
                          </div>
                        ) : (
                          t("choose_date")
                        )}
                      </div>
                    </div>
                  </fieldset>
                  <fieldset
                    className={(returnValue ? "" : "disabled") + " w-100"}
                  >
                    <legend className="d-flex">
                      <div className="me-2">Return</div>
                      <Form.Check
                        type="switch"
                        className="custom-switch"
                        style={{ marginTop: "-5px" }}
                        onChange={(val) => {
                          setReturnValue(val.target.checked);
                          if (!val.target.checked) {
                            setEndDate("");
                          }
                        }}
                      />
                    </legend>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        if (returnValue) {
                          setModal({
                            type: "endDate",
                            open: true,
                          });
                        }
                      }}
                    >
                      <div className="bg-light border-0 pe-2">
                        <Image src={CalenderOutIcon} />
                      </div>
                      <div className="border-0 fw-light text-center">
                        {endDate ? (
                          <div className="fw-normal">
                            {moment(endDate).format("DD MMM YYYY")}
                          </div>
                        ) : (
                          t("choose_date")
                        )}
                      </div>
                    </div>
                  </fieldset>
                  <fieldset
                    style={{ minWidth: "260px" }}
                    onClick={() => {
                      setModal({
                        type: "passanger",
                        open: true,
                      });
                    }}
                  >
                    <legend>Passenger & Cabin Class</legend>
                    <div className="d-flex align-items-center">
                      <div style={{ height: "32px" }}></div>
                      <div className="text-center">
                        {passanger} Passenger,{" "}
                        {cabinClass
                          .replace(/-/g, " ")
                          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                            letter.toUpperCase()
                          )}
                      </div>
                    </div>
                  </fieldset>
                </div>
                <Button
                  type="primary"
                  className="text-white mt-2"
                  onClick={() => {
                    if (
                      departuredSelected &&
                      destinationSelected &&
                      startDate
                    ) {
                      navigate(
                        `/flights?departure=${
                          departuredSelected?.code
                        }&destination=${
                          destinationSelected?.code
                        }&startDate=${moment(startDate).format(
                          "YYYY-MM-DD"
                        )}&endDate=${
                          endDate && moment(endDate).format("YYYY-MM-DD")
                        }&adult=${qtyAdult}&child=${qtyChild}&infant=${qtyInfant}&class=${cabinClass}&departure_city=${
                          departuredSelected?.name
                        }&destination_city=${destinationSelected?.name}`
                      );
                    }
                  }}
                >
                  {t("search")} {active === "2" && " Flight"}
                </Button>
              </div>
            </Tab>
          </Tabs>
        </div>
        <Modal
          show={modal.open && modal.type === "departure"}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Departure</Modal.Title>
          </Modal.Header>
          <Modal.Body className="city-modal">
            <InputGroup className="search-product">
              <InputGroup.Text className="bg-light border-end-0 py-3">
                <Image src={SearchIcon} />
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 fw-light"
                placeholder={t("search")}
                onKeyUp={(key) => setSearch(key?.target?.value)}
              />
            </InputGroup>
            <div className="text-primary ms-2 my-2">
              Popular Cities or Airports
            </div>
            <div className="list-group">
              {filteredLocations.length > 0 ? (
                filteredLocations.map((item, index) => (
                  // {sortedCity?.length > 0 ? (
                  //   sortedCity.map((item, index) => (
                  // {city?.length > 0 ? (
                  //   city.map((item, index) => (
                  <div
                    className="list px-3"
                    key={index}
                    onClick={() =>
                      //{
                      //   if (
                      //     destinationSelected &&
                      //     item._id === destinationSelected._id
                      //   ) {
                      //     toast.error(
                      //       "Departure cannot be the same as destination"
                      //     );
                      //   } else {
                      //     setDeparturedSelected(item);
                      //     handleClose();
                      //   }
                      // }
                      handleCityClick(item)
                    }
                  >
                    <div className="d-flex my-3 w-100">
                      <Image src={CityIcon} className="pe-4" />
                      <div className="city-detail">
                        <div className="city-name mb-1">{item.location}</div>
                        {/* <div className="city-desc">{item.location}</div> */}
                        <div className="city-desc">
                          {item.code + " - " + item.name}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="city-not-found">
                  <img src={CityNotFound} alt="" />
                  <h5>The airport you are looking for doesn't exist</h5>
                  <span className="text-muted fw-light">
                    Please change your search word
                  </span>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal.open && modal.type === "destination"}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("destination")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="city-modal">
            <InputGroup className="search-product">
              <InputGroup.Text className="bg-light border-end-0 py-3">
                <Image src={SearchIcon} />
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 fw-light"
                placeholder={t("search")}
                onKeyUp={(key) => setSearch(key?.target?.value)}
              />
            </InputGroup>
            <div className="text-primary ms-2 my-2">
              Popular Cities or Airports
            </div>
            <div className="list-group">
              {/* {sortedCity?.length > 0 ? (
                sortedCity.map((item, index) => ( */}
              {filteredLocations.length > 0 ? (
                filteredLocations.map((item, index) => (
                  <div
                    className="list px-3"
                    key={index}
                    onClick={() =>
                      //   {
                      //   if (
                      //     departuredSelected &&
                      //     item._id === departuredSelected._id
                      //   ) {
                      //     toast.error(
                      //       "Destination cannot be the same as departure"
                      //     );
                      //   } else {
                      //     setDestinationSelected(item);
                      //     handleClose();
                      //   }
                      // }
                      handleCityClick(item)
                    }
                  >
                    <div className="d-flex my-3 w-100">
                      <Image src={CityIcon} className="pe-4" />
                      <div className="city-detail">
                        <div className="city-name">{item.location}</div>
                        {/* <div className="city-desc">{item.location}</div> */}
                        <div className="city-desc">
                          {item.code + " - " + item.name}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="city-not-found">
                  <img src={CityNotFound} alt="" />
                  <h5>The airport you are looking for doesn't exist</h5>
                  <span className="text-muted fw-light">
                    Please change your search word
                  </span>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal.open && modal.type === "startDate"}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("choose_date")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="date-modal">
            <div>
              <DatePicker
                locale="id"
                inline
                onChange={(date) => {
                  if (moment(date).diff(moment(endDate), "days") > 0) {
                    toast.error("Start date must be before end date");
                  } else {
                    setStartDate(date);
                    handleClose();
                  }
                }}
                selected={startDate}
                filterDate={(date) => {
                  return moment() < date;
                }}
                className="inline-calender"
              />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal.open && modal.type === "endDate"}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("choose_date")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="date-modal">
            <div>
              <DatePicker
                locale="id"
                inline
                onChange={(date) => {
                  if (moment(startDate).diff(moment(date), "days") > 0) {
                    toast.error("End date must be after start date");
                  } else {
                    setEndDate(date);
                    handleClose();
                  }
                }}
                selected={endDate}
                filterDate={(date) => {
                  return startDate ? moment(startDate) < date : moment() < date;
                }}
                className="inline-calender"
              />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal.open && modal.type === "passanger"}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Passanger and Cabin Class</Modal.Title>
          </Modal.Header>
          <Modal.Body className="date-modal">
            <Row>
              <Col lg="6" xl="6" className="first-vertical">
                <div className="h5">Passenger</div>
                <div className="d-flex align-items-center justify-content-between passanger">
                  <div className="w-100">
                    <div>{t("adult")}</div>
                    <div className="text-grey fw-lighter small pt-1 font-sans-force">
                      Age 12+
                    </div>
                  </div>
                  <div className="button-passanger">
                    <Button onClick={() => onChangeQty("minus", "adult")}>
                      -
                    </Button>
                    <div className="value-passanger">{qtyAdult}</div>
                    <Button onClick={() => onChangeQty("plus", "adult")}>
                      +
                    </Button>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between passanger">
                  <div className="w-100">
                    <div>Children</div>
                    <div className="text-grey fw-lighter small pt-1 font-sans-force">
                      Age 2-11
                    </div>
                  </div>
                  <div className="button-passanger">
                    <Button onClick={() => onChangeQty("minus", "child")}>
                      -
                    </Button>
                    <div className="value-passanger">{qtyChild}</div>
                    <Button onClick={() => onChangeQty("plus", "child")}>
                      +
                    </Button>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between passanger">
                  <div className="w-100">
                    <div>Infant</div>
                    <div className="text-grey fw-lighter small pt-1 font-sans-force">
                      Under 2
                    </div>
                  </div>
                  <div className="button-passanger">
                    <Button onClick={() => onChangeQty("minus", "infant")}>
                      -
                    </Button>
                    <div className="value-passanger">{qtyInfant}</div>
                    <Button onClick={() => onChangeQty("plus", "infant")}>
                      +
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg="6" xl="6">
                <div className="h5">Cabin Class</div>
                <label className="radio-cabin" for="economy" role="button">
                  <div
                    className={
                      (cabinClass === "economy" ? "fw-normal text-black" : "") +
                      " form-check-label"
                    }
                  >
                    Economy
                  </div>
                  <input
                    name="cabinClass"
                    type="radio"
                    id="economy"
                    className="form-check-input"
                    value="economy"
                    checked={cabinClass === "economy"}
                    onClick={() => setCabinClass("economy")}
                  />
                </label>
                <label className="radio-cabin" for="business" role="button">
                  <div
                    className={
                      (cabinClass === "business"
                        ? "fw-normal text-black"
                        : "") + " form-check-label"
                    }
                  >
                    Business
                  </div>
                  <input
                    name="cabinClass"
                    type="radio"
                    id="business"
                    className="form-check-input"
                    value="business"
                    checked={cabinClass === "business"}
                    onClick={() => setCabinClass("business")}
                  />
                </label>
                <label className="radio-cabin" for="firstclass" role="button">
                  <div
                    className={
                      (cabinClass === "firstclass"
                        ? "fw-normal text-black"
                        : "") + " form-check-label"
                    }
                  >
                    First Class
                  </div>
                  <input
                    name="cabinClass"
                    type="radio"
                    id="firstclass"
                    className="form-check-input"
                    value="firstclass"
                    checked={cabinClass === "firstclass"}
                    onClick={() => setCabinClass("firstclass")}
                  />
                </label>
              </Col>
              <Col lg="12" xl="12" className="mt-2">
                <Button
                  type="primary"
                  className="text-white w-100 ml-auto"
                  onClick={() => {
                    setPassanger(qtyAdult + qtyChild + qtyInfant);
                    handleClose();
                  }}
                >
                  {t("save")}
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
export default HeroSection;
