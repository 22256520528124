import React from "react";
import { BrowserView } from "react-device-detect";
import PartnersDesktopPage from "./desktop";

function PartnersPage() {
  return (
    // <BrowserView>
      <PartnersDesktopPage />
    // </BrowserView>
  );
}
export default PartnersPage;
