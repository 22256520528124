import React from "react";
import { BrowserView } from "react-device-detect";
import ReceiptDesktopPage from "./desktop/receipt";

function ReceiptPage() {
  return (
    // <BrowserView>
      <ReceiptDesktopPage />
    // </BrowserView>
  );
}
export default ReceiptPage;
