import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
// import SortIcon from "../../../../assets/icons/sort.svg";
import FilterLocalExperience from "./filter";
import ItemLocalExperience from "../../../../component/desktop/card/itemLocalExperience";
import PaginationCard from "../../../../component/desktop/card/pagination";
import UserContext from "../../../../services/user";
import { SkeletonCustom } from "../../../../component/global/skeleton";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ListLocalExperience() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { loading, addMaster } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    type: ["open trip", "private trip"],
    startPrice: 1,
    endPrice: 100000000,
    location: [],
    search: searchParams.get("search") ?? "",
    bannerId: searchParams.get("banner"),
    categoryId: searchParams.get("category"),
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(12);

  const getData = async () => {
    const newData = await addMaster(
      `product/filter-products?page=${page}&limit=${pageLimit}`,
      filter
    );
    if (newData) {
      setData(newData.data);
      setTotal(newData.total);
    }
  };

  useEffect(() => {
    if (filter?.type?.length <= 0) {
      filter.type = ["open trip", "private trip"];
    }
    getData();
  }, [pageLimit, page, filter]);

  return (
    <Container>
      <div className="d-flex align-items-between pb-5">
        <div className="w-50 h5 fw-bold">
          {t("recommended_local_experience")}
        </div>
        <div className="w-50 text-primary text-end">
          {/* <Image src={SortIcon} /> */}
          {/* <span>{t("sort_by")}</span> */}
        </div>
      </div>
      <Row className="mb-5 align-items-start search-le">
        <Col sm="12" md="12" lg="4" xl="3" className="mb-5">
          <FilterLocalExperience filter={filter} setFilter={setFilter} />
        </Col>
        <Col sm="12" md="12" lg="8" xl="9">
          {loading ? (
            <SkeletonCustom height="100px" />
          ) : (
            <Row className="section2 align-items-center">
              {data &&
                data.map((item, index) => (
                  <Col
                    sm="12"
                    md="6"
                    lg="4"
                    xl="4"
                    className="mb-3"
                    key={index}
                  >
                    <ItemLocalExperience data={item} />
                  </Col>
                ))}
              <Col sm="12" md="12" lg="12" xl="12" className="mt-5">
                <PaginationCard
                  itemsCount={total}
                  itemsPerPage={pageLimit}
                  currentPage={page}
                  setCurrentPage={setPage}
                  alwaysShown={false}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default ListLocalExperience;
