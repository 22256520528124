import React from "react";
import { Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import StarIcon from "../../../assets/icons/star.svg";

function ItemLocalExperience(props) {
  return (
    <Link
      to={`/local-experience/detail/${props?.data?._id}`}
      className="text-dark text-decoration-none"
    >
      <Card className="border-0 package">
        <Image
          className="card-img-top rounded-top"
          src={
            process.env.REACT_APP_FILE_URL +
            "/" +
            props?.data?.generalInformation?.picture[0]
          }
        />
        <Card.Body className="d-flex flex-column border rounded-bottom">
          <div className="d-flex align-items-center fw-light small pb-2 text-muted">
            {/* <Image src={StarIcon} className="pe-1" /> */}
            {/* <div className="fw-bold pe-1">{props?.data?.rating || 5}</div> */}
            {/* <div>({props?.data?.rating_count || "-"})</div> */}
            {/* <div className="px-1">&#x2022;</div> */}
            <div>{props?.data?.productDetail?.city}</div>
          </div>
          <div className="fw-bold item-le-name">{props?.data?.generalInformation?.name}</div>
          <div className="mt-auto">
            <div className="fw-light mb-1">{props?.data?.pricingDetail?.duration} Day</div>
            {props?.data?.pricingDetail?.publishedPrice !== props.data?.pricingDetail?.crossOutPrice && (
              <span className="small text-decoration-line-through text-grey-custom">
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 0,
                }).format(props?.data?.pricingDetail?.crossOutPrice)}
              </span>
            )}
            <div className="text-primary mt-1">
              <span className="fw-bold fs-5">
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 0,
                }).format(props?.data?.pricingDetail?.publishedPrice)}
              </span>
              <span> / pax</span>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Link>
  );
}

export default ItemLocalExperience;
