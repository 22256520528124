//User
export const GET_ALL = "GET_ALL"
export const GET = "GET"
export const ADD = "ADD"
export const UPDATE = "UPDATE"
export const DELETE = "DELETE"
export const BOOK = "BOOK"
export const PAYMENT = "PAYMENT"
export const REGISTER = "REGISTER"

//Unit
export const GET_ALL_UNIT = "GET_ALL_UNIT"
export const GET_UNIT = "GET_UNIT"
export const ADD_UNIT = "ADD_UNIT"
export const UPDATE_UNIT = "UPDATE_UNIT"
export const DELETE_UNIT = "DELETE_UNIT"