import React from "react";
import DesktopWrapper from "../../../../layout/desktop/DesktopWrapper";
import GuideList from "./guideList";

function ColabExperiencesPages() {
  return (
    <DesktopWrapper>
      <GuideList />
    </DesktopWrapper>
  );
}

export default ColabExperiencesPages;
