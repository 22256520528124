import React, { useState } from "react";
import { Accordion, Card, Form, InputGroup } from "react-bootstrap";
import MultiRangeSlider from "multi-range-slider-react";
import { useTranslation } from "react-i18next";

function FilterLocalExperience(props) {
  const { t } = useTranslation();
  const [type, setTipe] = useState([
    {
      id: "open trip",
      name: "Open Trip",
    },
    {
      id: "private trip",
      name: "Private Trip",
    },
  ]);
  const [price, setPrice] = useState([1, 20000000]);
  // const [location, setLocation] = useState([
  //   {
  //     id: "Labuan Bajo",
  //     name: "Labuan Bajo",
  //   },
  //   {
  //     id: "Lombok",
  //     name: "Lombok",
  //   },
  //   {
  //     id: "Bali",
  //     name: "Bali",
  //   },
  //   {
  //     id: "Yogyakarta",
  //     name: "Yogyakarta",
  //   },
  //   {
  //     id: "Jakarta",
  //     name: "Jakarta",
  //   },
  //   {
  //     id: "Bandung",
  //     name: "Bandung",
  //   },
  //   {
  //     id: "Kepulauan Seribu",
  //     name: "Kepulauan Seribu",
  //   },
  // ]);

  const handlePrice = (e) => {
    if (
      e.minValue !== props.filter?.startPrice ||
      e.maxValue !== props.filter?.endPrice
    ) {
      props.setFilter({
        ...props.filter,
        startPrice: e.minValue,
        endPrice: e.maxValue,
      });
    }
  };

  const onChangeType = (e, item) => {
    const type = props.filter?.type;
    if (e?.target?.checked) {
      type.push(item.id);
    } else {
      var i = type.indexOf(item.id);
      if (i !== -1) {
        type.splice(i, 1);
      }
    }
    props.setFilter({
      ...props.filter,
      type: type,
    });
  };

  const onChangeLocation = (e, item) => {
    const location = props.filter?.location;
    if (e?.target?.checked) {
      location.push(item.id);
    } else {
      var i = location.indexOf(item.id);
      if (i !== -1) {
        location.splice(i, 1);
      }
    }
    props.setFilter({
      ...props.filter,
      location: location,
    });
  };

  return (
    <Card className="border-0 shadow">
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between py-3">
          <span>Filter</span>
          <span
            className="text-primary"
            role="button"
            onClick={() =>
              props.setFilter({
                tipe: [],
                startPrice: 0,
                endPrice: 200000000,
                location: [],
              })
            }
          >
            Reset
          </span>
        </div>
        <div className="filter-search">
          <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Tipe Perjalanan</Accordion.Header>
              <Accordion.Body>
                {type &&
                  type.map((item, index) => (
                    <Form.Check
                      key={index}
                      reverse
                      label={item.name}
                      name={`group-${item.id}`}
                      type="checkbox"
                      id={`reverse-checkbox-${item.id}`}
                      checked={props?.filter?.type?.includes(item.id)}
                      onChange={(checked) => {
                        onChangeType(checked, item);
                      }}
                    />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>{t("price_range")}</Accordion.Header>
              <Accordion.Body>
                <div className="d-flex mb-2 align-items-center">
                  <InputGroup className="price">
                    <InputGroup.Text id="start-price" className="p-1">
                      IDR
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Start Price"
                      aria-label="Start Price"
                      aria-describedby="start-price"
                      className="p-1"
                      type="number"
                      value={props?.filter?.startPrice}
                      onChange={(val) =>
                        props.setFilter({
                          ...props.filter,
                          startPrice: val?.target?.value,
                        })
                      }
                      // readOnly
                    />
                  </InputGroup>
                  <div className="line-middle"></div>
                  <InputGroup className="price">
                    <InputGroup.Text id="end-price" className="p-1 fs-sm">
                      IDR
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="End Price"
                      aria-label="End Price"
                      aria-describedby="end-price"
                      className="p-1"
                      type="number"
                      value={props?.filter?.endPrice}
                      onChange={(val) =>
                        props.setFilter({
                          ...props.filter,
                          endPrice: val?.target?.value,
                        })
                      }
                      // readOnly
                    />
                  </InputGroup>
                </div>
                <MultiRangeSlider
                  baseClassName={"multi-range-slider"}
                  label={false}
                  ruler={false}
                  min={price[0]}
                  max={price[1]}
                  step={10000}
                  stepOnly={true}
                  minValue={props?.filter?.startPrice}
                  maxValue={props?.filter?.endPrice}
                  onChange={(e) => {
                    handlePrice(e);
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="2">
              <Accordion.Header>{t("location")}</Accordion.Header>
              <Accordion.Body>
                {location &&
                  location.map((item, index) => (
                    <Form.Check
                      key={index}
                      reverse
                      label={item.name}
                      name={`group-${item.id}`}
                      type="checkbox"
                      id={`reverse-checkbox-${item.id}`}
                      onClick={(checked) => {
                        onChangeLocation(checked, item);
                      }}
                    />
                  ))}
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </div>
      </Card.Body>
    </Card>
  );
}

export default FilterLocalExperience;
