import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useState } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import ShowIcon from "../../assets/icons/show.svg";
import HideIcon from "../../assets/icons/hide.svg";
import UserContext from "../../services/user";
import AuthWrapper from "../../layout/desktop/AuthWrapper";
import SocialMediaLogin from "./socialMediaLogin";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    title: yup.string().required("Title is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    countryCode: yup.string().required("Country Code is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
    termsConditions: yup.string().required("Terms & Conditions is required"),
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character. Ex: P4sw0rd!23"
      ),
    cPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

function RegisterPage(params) {
  const { t } = useTranslation();
  const { loading, addMaster } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  let navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: "",
      firstName: "",
      lastName: "",
      countryCode: "",
      phoneNumber: "",
      email: "",
      password: "",
      cPassword: "",
      termsConditions: "",
    },
  });

  const title = [
    {
      value: "Mr",
      label: "Mr",
    },
    {
      value: "Ms",
      label: "Ms",
    },
    {
      value: "Mrs",
      label: "Mrs",
    },
  ];

  const countryCode = [
    {
      value: "62",
      label: "+62",
    },
  ];

  const onSubmit = async (values) => {
    let response = await addMaster(
      "user/register-member",
      values,
      "Registration completed successfully. Please check your registered email for email verification"
    );
    if (!response?.error) {
      navigate("/");
    } else {
      toast.error(response?.error);
    }
  };

  return (
    <AuthWrapper type="register">
      <h4 className="mb-3">{t("register")}</h4>
      <h6 className="text-grey fw-light">
        {t("register_now_for_convenience_in_booking_tour_packages")}
      </h6>
      <form className="form my-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4 text-start">
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                ref={ref}
                className="select text-start mb-2"
                classNamePrefix="select"
                placeholder={t("title")}
                isSearchable={false}
                value={title.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                options={title}
              />
            )}
          />
          <small className="text-danger mt-2">{errors.title?.message}</small>
        </div>
        <div className="mb-4 text-start">
          <Form.Control
            {...register("firstName")}
            className="control-input mt-2 mb-2"
            placeholder={t("first_name")}
          />
          <small className="text-danger mt-2">
            {errors.firstName?.message}
          </small>
        </div>
        <div className="mb-4 text-start">
          <Form.Control
            {...register("lastName")}
            className="control-input mt-2 mb-2"
            placeholder={t("last_name")}
          />
          <small className="text-danger mt-2">{errors.lastName?.message}</small>
        </div>
        <div className="mb-4 text-start">
          <Form.Control
            {...register("email")}
            type="email"
            className="control-input mt-2 mb-2"
            placeholder={t("email_address")}
          />
          <small className="text-danger mt-2">{errors.email?.message}</small>
        </div>
        <div className="mb-4 text-start">
          <Row className="mt-2">
            <Col xs="4" sm="4" md="4" lg="4" xl="4">
              <Controller
                control={control}
                name="countryCode"
                render={({ field: { onChange, value, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    className="select text-start mb-2"
                    classNamePrefix="select"
                    isSearchable={false}
                    placeholder="+xx"
                    value={countryCode.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                    options={countryCode}
                  />
                )}
              />
              <small className="text-danger mt-2">
                {errors.countryCode?.message}
              </small>
            </Col>
            <Col xs="8" sm="8" md="8" lg="8" xl="8">
              <Form.Control
                {...register("phoneNumber")}
                className="control-input mb-2"
                placeholder={t("phone_number")}
              />
              <small className="text-danger mt-2">
                {errors.phoneNumber?.message}
              </small>
            </Col>
          </Row>
        </div>
        <div className="mb-4 text-start">
          <div className="position-relative mb-2">
            <Form.Control
              {...register("password")}
              type={showPassword ? "text" : "password"}
              className="control-input mt-2"
              placeholder={t("password")}
            />
            <span
              className="password-icon"
              onClick={() => setShowPassword(showPassword ? false : true)}
            >
              <Image src={showPassword ? ShowIcon : HideIcon} />
            </span>
          </div>
          <small className="text-danger mt-2">{errors.password?.message}</small>
        </div>
        <div className="mb-4 text-start">
          <div className="position-relative mb-2">
            <Form.Control
              {...register("cPassword")}
              type={showPassword ? "text" : "password"}
              className="control-input mt-2"
              placeholder={t("confirm_password")}
            />
            <span
              className="password-icon"
              onClick={() => setShowPassword(showPassword ? false : true)}
            >
              <Image src={showPassword ? ShowIcon : HideIcon} />
            </span>
          </div>
          <small className="text-danger mt-2">
            {errors.cPassword?.message}
          </small>
        </div>
        <div className="mb-4 text-start">
          <div className="radio-group d-flex small text-muted mb-2">
            <Form.Check type="checkbox" id="agreement" className="pb-2">
              <Form.Check.Input
                {...register("termsConditions")}
                type="checkbox"
                className="mt-1"
                value="true"
              />
              <Form.Check.Label>
                {t("i_agree_with")}{" "}
                <Link className="text-primary" to="/privacy-policy">
                  {t("privacy_policy")}
                </Link>{" "}
                &{" "}
                <Link className="text-primary" to="/terms-conditions">
                  {t("terms_conditions")}
                </Link>{" "}
                {t("use_pigijo")}.
              </Form.Check.Label>
            </Form.Check>
          </div>
          <small className="text-danger mt-2">
            {errors.termsConditions?.message}
          </small>
        </div>
        <Button
          variant="primary"
          className="text-white w-100 mt-1"
          type="submit"
        >
          {t("register")}
        </Button>
        <SocialMediaLogin />
        <div className="mt-4 fw-light">
          {t("already_have_an_account")}{" "}
          <Link
            to="/login"
            className="text-primary text-decoration-none fw-normal"
          >
            {t("login")}
          </Link>
        </div>
      </form>
    </AuthWrapper>
  );
}

export default RegisterPage;
