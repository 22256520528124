import React, { useContext, useEffect, useState } from "react";
import { Badge, Card, Col, Image, Row } from "react-bootstrap";
import LocalExperienceIcon from "../../../../assets/icons/localExperience.svg";
import LeftArrowIcon from "../../../../assets/icons/leftArrow.svg";
import WhatsappIcon from "../../../../assets/icons/whatsapp.svg";
import EmailIcon from "../../../../assets/icons/email.svg";
import CautionIcon from "../../../../assets/icons/caution.svg";
import { Link, useParams } from "react-router-dom";
import UserContext from "../../../../services/user";
import moment from "moment";
import { AirlineImageConverter } from "../../../../config/helper/maskapai";
import { useTranslation } from "react-i18next";

function OrderFlightDetail(params) {
  const { t } = useTranslation();
  const { getMaster, loading } = useContext(UserContext);
  const [data, setData] = useState({});
  const [dataDetail, setDataDetail] = useState([]);
  const { id } = useParams();

  const getData = async () => {
    const newData = await getMaster(`voltras/got-order`, id);
    if (newData?.data) {
      setDataDetail([]);
      setData(newData?.data);
      newData?.data?.items?.map((item, key) => {
        if (key !== 0 && item?.pnrid !== newData?.data?.items[key - 1]?.pnrid) {
          getDataDetail(item?.pnrid);
        } else if (key === 0) {
          getDataDetail(item?.pnrid);
        }
      });
    }
  };

  const getDataDetail = async (pnrid) => {
    const newData = await getMaster(`voltras/got-order/ticket`, pnrid);
    if (newData?.data) {
      setDataDetail((current) => [...current, newData?.data?.response]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Link to="/order-flight" className="text-primary text-decoration-none">
        <Image src={LeftArrowIcon} />
        <span className="ps-2">{t("back_to_active_order")}</span>
      </Link>
      <Card className="mt-4 border-0 shadow-sm">
        <Card.Body>
          <div className="d-flex align-items-center mb-3">
            <Image src={LocalExperienceIcon} />
            <span className="small ps-2 fw-light">{data.type || "Flight"}</span>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div></div>
            <div className="text-grey fw-light mb-2">
              {t("booking_id")}: {data.invoiceNumber}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div></div>
            <div className="fw-light mb-2">
              {dataDetail?.map((item, index) => (
                <div key={index}>{t("booking_code")}: <strong>{item?.bookingcode}</strong></div>
              ))}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div></div>
            <div className="fw-light mb-2">
              {dataDetail?.map((item, index) => (
                <div key={index}>Status: <strong>{item?.status}</strong></div>
              ))}
            </div>
          </div>
          <hr></hr>
          <div className="d-flex align-items-center justify-content-between">
            <div>{t("total_payment")}</div>
            <div className="text-primary">
              {new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              }).format(data?.grossAmount)}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="mt-4 border-0 shadow-sm">
        <Card.Body>
          <div className="h5 mb-3">{t("flight_details")}</div>
          {dataDetail?.map((mainItem, mainIndex) =>
            mainItem?.listflight?.flight?.length > 1 ? (
              mainItem?.listflight?.flight?.map((item, index) => (
                <div className="mb-4" key={index + "_" + mainIndex}>
                  {item?.type}
                  <div className="timeline-flight">
                    <div className="item">
                      <div className="date-time">
                        <div className="time">{item?.departtime}</div>
                        <div className="date">
                          {moment(item?.departdate).format("ddd, DD MMM YYYY")}
                        </div>
                      </div>
                      <div className="event">
                        <div className="title">{item?.departure}</div>
                        <div className="description">{item?.departure}</div>
                        <div className="flight">
                          {AirlineImageConverter(item?.airlinecode)}
                          {item?.flightno}
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="date-time">
                        <div className="time">{item?.arrivaltime}</div>
                        <div className="date">
                          {moment(item?.departdate).format("ddd, DD MMM YYYY")}
                        </div>
                      </div>
                      <div className="event">
                        <div className="title">{item?.arrival}</div>
                        <div className="description">{item?.arrival}</div>
                      </div>
                    </div>
                  </div>
                  {index !== mainItem?.listflight?.flight?.length - 1 && mainItem?.listflight?.flight?.length>0 && mainItem?.listflight?.flight?.[index-1]?.type===mainItem?.listflight?.flight?.[index]?.type && (
                    <div className="w-100 text-center my-4">
                      <div className="flight">Transit</div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="timeline-flight">
                <div className="item">
                  <div className="date-time">
                    <div className="time">
                      {mainItem?.listflight?.flight?.departtime}
                    </div>
                    <div className="date">
                      {moment(mainItem?.listflight?.flight?.departdate).format(
                        "ddd, DD MMM YYYY"
                      )}
                    </div>
                  </div>
                  <div className="event">
                    <div className="title">
                      {mainItem?.listflight?.flight?.fromcity}
                    </div>
                    <div className="description">
                      {mainItem?.listflight?.flight?.fromcity}
                    </div>
                    <div className="flight">
                      {AirlineImageConverter(
                        data?.departure?.listflight?.flight?.airlinecode
                      )}
                      {mainItem?.listflight?.flight?.flightno}
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="date-time">
                    <div className="time">
                      {mainItem?.listflight?.flight?.arrivaltime}
                    </div>
                    <div className="date">
                      {moment(mainItem?.listflight?.flight?.departdate).format(
                        "ddd, DD MMM YYYY"
                      )}
                    </div>
                  </div>
                  <div className="event">
                    <div className="title">
                      {mainItem?.listflight?.flight?.tocity}
                    </div>
                    <div className="description">
                      {mainItem?.listflight?.flight?.tocity}
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </Card.Body>
      </Card>
      <Card className="mt-4 border-0 shadow-sm">
        <Card.Body>
          <div className="h5 mb-4">{t("passanger_details")}</div>
          {data?.customers &&
            data?.customers?.map(
              (item, index) =>
                item?.firstname && (
                  <div className="d-flex mb-4" key={index}>
                    <div className="h5 fw-light" style={{ width: "20px" }}>
                      {index + 1}.
                    </div>
                    <div className="fw-light">
                      <div className="d-flex align-items-center fw-light pb-2">
                        <div className="h5 mb-0 pe-3">
                          {item?.firstname + " " + item?.lastname}
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <div style={{ minWidth: "150px" }}>ID Number</div>
                        <div>: {item?.id}</div>
                      </div>
                    </div>
                  </div>
                )
            )}
        </Card.Body>
      </Card>
      <Card className="mt-4 border-0 shadow-sm">
        <Card.Body>
          <div className="h5 mb-4">{t("order_summary")}</div>
          <hr></hr>
          <div className="d-flex align-items-center justify-content-between text-muted small fw-light mb-3 mt-4">
            <div>{data.customers?.length} {t("passanger")}</div>
            <div>
              {" "}
              {new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              }).format(data?.grossAmount)}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between text-muted small fw-light mb-3">
            <div>{t("admin_fee")}</div>
            <div>Rp0</div>
          </div>
          <div className="d-flex align-items-center justify-content-between text-muted small fw-light mb-4">
            <div>{t("total_payment")}</div>
            <div>
              {" "}
              {new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              }).format(data?.grossAmount)}
            </div>
          </div>
          <hr></hr>
          <div className="mb-4 mt-4">{t("payment_details")}</div>
          <div className="d-flex align-items-center justify-content-between text-muted small fw-light mb-3">
            <div>{t("payment_method")}</div>
            {data.va && (
              <div>
                {(data?.paymentMethod?.toUpperCase() || "") +
                  " " +
                  data.va?.bank?.toUpperCase()}
              </div>
            )}
            {data.paymentMethod === "credit_card" && (
              <div>{data.paymentMethod?.toUpperCase()}</div>
            )}
            {(data.paymentMethod === "gopay" || data.paymentMethod === "shopeepay") && (
              <div>{data.paymentMethod?.toUpperCase()}</div>
            )}
            {data.paymentMethod === "echannel" && (
              <div>
                {data.paymentMethod?.toUpperCase() +
                  " " +
                  data.bill?.bank?.toUpperCase()}
              </div>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between text-muted small fw-light mb-3">
            <div>{t("va_number")}</div>
            {data.va && (
              <div>{data.va?.vaNumber}</div>
            )}
            {data.paymentMethod === "credit_card" && <div>-</div>}
            {(data.paymentMethod === "gopay" ||
              data.paymentMethod === "shopeepay") && (
              <a
                target="_blank"
                rel="noreferrer"
                className="text-primary"
                href={
                  data?.eWalletActions?.length > 0 &&
                  data?.eWalletActions[1]?.url
                }
              >
                Pay Here
              </a>
            )}
            {data.paymentMethod === "echannel" && (
              <div>{data.bill?.billerCode + " " + data.bill?.billKey}</div>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between text-muted small fw-light mb-3">
            <div>{t("date_transaction")}</div>
            <div>{moment(data.createtAt).format("LLL")}</div>
          </div>
        </Card.Body>
      </Card>
      <Card className="mt-4 border-0 shadow-sm">
        <Card.Body>
          <Row className="small">
            <Col sm="12" md="6" lg="6" xl="6">
              <div className="d-flex align-items-start">
                <Image src={WhatsappIcon} />
                <div className="ps-2">
                  <div className="mb-2">(+62) 082246598802</div>
                  <div className="d-flex text-muted fw-light mb-1">
                    <div style={{ minWidth: "200px" }}>Monday - Friday</div>
                    <div>: 09.00 - 17.00</div>
                  </div>
                  <div className="d-flex text-muted fw-light">
                    <div style={{ minWidth: "200px" }}>
                      Saturday, Monday, Holiday
                    </div>
                    <div>: 11.00 - 15.00</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="6" xl="6">
              <div className="d-flex align-items-start">
                <Image src={EmailIcon} />
                <div className="ps-2">
                  <div className="mb-2">cs@pigijo.com / info@pigijo.com</div>
                  <div className="d-flex text-muted fw-light mb-1">
                    Maximum Response 2 x 24 H
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-4 border-0 shadow-sm">
        <Card.Body className="d-flex align-items-center">
          <Image src={CautionIcon} />
          <div className="ps-4">
            <div className="h5">Be a Smart Traveler!</div>
            <div className="fw-light">
              Pigijo is not responsible for things / communications that occur
              outside of orders.
            </div>
            <div className="fw-light">
              If you need assistance, please contact Pigijo Customer Service.
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default OrderFlightDetail;
