import React from "react";
import { Button, Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

function ItemDestination(props) {
  return (
    <Link
      to={`/destination/${props?.data?._id}`}
      className="text-dark text-decoration-none"
    >
      <Card className="border-0 package position-relative">
        <Image
          className="card-img-top rounded destination-picture"
          src={process.env.REACT_APP_FILE_URL + "/" + props?.data?.picture}
        />
        <div className="d-flex align-items-center justify-content-between text-white position-absolute bottom-0 p-3 w-100">
          <div className="small">{props?.data?.caption}</div>
          <div className="small">
            <Button variant="outline-light" className="btn btn-sm small">
              Explore
            </Button>
          </div>
        </div>
      </Card>
    </Link>
  );
}

export default ItemDestination;
