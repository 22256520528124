import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Image, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import RightArrow from "../../../../assets/icons/rightArrow.svg";
import UserContext from "../../../../services/user";
import ItemGroup from "../../../../component/desktop/card/itemGroup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

function Section2(props) {
  const { t } = useTranslation();
  const { getAllMaster } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [key, setKey] = useState(0);

  const getData = async () => {
    const newData = await getAllMaster(`menu/recomendeds`);
    if (newData) {
      setData(newData.data);
      props.setCategory(newData.data?.[0]?.category);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      // setKey(data[0].id);
      const savedKey = localStorage.getItem("activeTabKey");
      setKey(savedKey !== null ? parseInt(savedKey, 10) : data[0].id);
    }
  }, [data]);

  return (
    <Container className="section2">
      <div className="mb-5">
        <div className="d-flex align-items-center justify-content-between fs-5 mt-5 pb-4">
          <div className="fs-4 fw-bold w-75" style={{ width: "60%" }}>
            {props?.type}
          </div>
          <Link
            className="text-primary text-decoration-none see-all-1"
            to={`/local-experience/${encodeURIComponent(data?.[key]?.name)}`}
          >
            {t("see_all")} <Image src={RightArrow} />
          </Link>
        </div>

        <Tabs
          id="controlled-tab"
          activeKey={key}
          onSelect={(k) => {
            setKey(k);
            props.setCategory(data?.[k]?.category);
            localStorage.setItem("activeTabKey", k);
            // toast.info(`Selected tab: ${data?.[k]?.name}`);
            // toast.info(`Selected id: ${data?.[k]?._id}`);
          }}
          className="mb-4"
        >
          {data?.map((item, index) => (
            <Tab eventKey={index} title={item?.name} key={index}>
              <ItemGroup data={props.data} />
              {/* <Row className={"overflow-card align-items-center "+(data?.length>1&&("full"))}>
                {props?.data &&
                  props?.data.map((item, index) => (
                    <Col
                      sm="12"
                      md="6"
                      lg="4"
                      xl="3"
                      key={index}
                      className="mb-3"
                    >
                      <ItemLocalExperience data={item} />
                    </Col>
                  ))}
              </Row> */}
            </Tab>
          ))}
        </Tabs>
        <Button
          as={Link}
          to={`/local-experience/${encodeURIComponent(data?.[key]?.name)}`}
          className="see-all-2 color2 w-100 text-center text-info"
          variant="light-info"
        >
          View all Local Experience
        </Button>
      </div>
      <hr></hr>
    </Container>
  );
}

export default Section2;
