import AuthWrapper from "../../layout/desktop/AuthWrapper";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Image, Modal } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ShowIcon from "../../assets/icons/show.svg";
import HideIcon from "../../assets/icons/hide.svg";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import UserContext from "../../services/user";

const schema = yup
  .object({
    newPassword: yup
      .string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    cNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  })
  .required();

function ResetPasswordPage(params) {
  const { loading, addMaster } = useContext(UserContext);
  let search = window.location.search;
  let paramsQ = new URLSearchParams(search);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      newPassword: "",
      cNewPassword: "",
    },
  });

  const onSubmit = async (values) => {
    values._id = paramsQ.get("id");
    values.code = paramsQ.get("code");
    let response = await addMaster(
      "user/forgot-password",
      values,
      "Reset password successfully. Please login!"
    );
    if (!response?.err) {
      navigate("/");
    }
  };

  return (
    <AuthWrapper>
      <h4 className="mb-3">Reset Password</h4>
      <h6 className="text-grey fw-light">
        Please change your new Password, and log back into the account.
      </h6>
      <form className="form my-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <div className="position-relative">
            <Form.Control
              type={showPassword ? "text" : "password"}
              {...register("newPassword")}
              className="control-input mt-2"
              placeholder="*******"
            />
            <span
              className="password-icon"
              onClick={() => setShowPassword(showPassword ? false : true)}
            >
              <Image src={showPassword ? ShowIcon : HideIcon} />
            </span>
          </div>
          <small className="text-danger">{errors.newPassword?.message}</small>
        </div>
        <div className="mb-4">
          <div className="position-relative">
            <Form.Control
              type={showPassword ? "text" : "password"}
              {...register("cNewPassword")}
              className="control-input mt-2"
              placeholder="*******"
            />
            <span
              className="password-icon"
              onClick={() => setShowPassword(showPassword ? false : true)}
            >
              <Image src={showPassword ? ShowIcon : HideIcon} />
            </span>
          </div>
          <small className="text-danger">{errors.cNewPassword?.message}</small>
        </div>
        <Button
          variant="primary"
          className="text-white w-100 mt-1"
          type="submit"
        >
          Send
        </Button>
      </form>
    </AuthWrapper>
  );
}

export default ResetPasswordPage;
