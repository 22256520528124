import React from "react";
import { BrowserView } from "react-device-detect";
import DetailLocalExperienceDesktopPage from "./desktop/detail";

function DetailLocalExperiencePage() {
  return (
    // <BrowserView>
      <DetailLocalExperienceDesktopPage />
    // </BrowserView>
  );
}
export default DetailLocalExperiencePage;
