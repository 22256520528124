import React, { useEffect, useState } from "react";
import { Accordion, Card, Form, InputGroup } from "react-bootstrap";
import MultiRangeSlider from "multi-range-slider-react";
import { useTranslation } from "react-i18next";
import MaskapaiData from "../../../../assets/maskapai.json";
import { AirlineImageConverter } from "../../../../config/helper/maskapai";

function FilterFlight(props) {
  const { t } = useTranslation();
  const [type, setType] = useState([
    {
      id: "langsung",
      name: "Langsung",
    },
    {
      id: "1 transit",
      name: "1 Transit",
    },
    {
      id: "2 transit",
      name: "2+ Transit",
    },
  ]);
  const [facility, setFacility] = useState([
    {
      id: "baggage",
      name: t("baggage"),
    },
    {
      id: "meal",
      name: t("meal"),
    },
  ]);
  const [startDate, setStartDate] = useState([
    {
      id: "quarter1",
      start: "00:00",
      end: "05:59",
    },
    {
      id: "quarter2",
      start: "06:00",
      end: "11:59",
    },
    {
      id: "quarter3",
      start: "12:00",
      end: "17:59",
    },
    {
      id: "quarter4",
      start: "18:00",
      end: "23:59",
    },
  ]);
  const [endDate, setEndDate] = useState([
    {
      id: "quarter1",
      start: "00:00",
      end: "05:59",
    },
    {
      id: "quarter2",
      start: "06:00",
      end: "11:59",
    },
    {
      id: "quarter3",
      start: "12:00",
      end: "17:59",
    },
    {
      id: "quarter4",
      start: "18:00",
      end: "23:59",
    },
  ]);
  const [price, setPrice] = useState([100000, 20000000]);
  const [maskapai, setMaskapai] = useState(MaskapaiData);

  const handlePrice = (e) => {
    if (
      e.minValue !== props.filterFlight?.startPrice ||
      e.maxValue !== props.filterFlight?.endPrice
    ) {
      props.setFilterFlight({
        ...props.filterFlight,
        startPrice: e.minValue,
        endPrice: e.maxValue,
      });
    }
  };

  const onChangeType = (e, item) => {
    const type = props.filterFlight?.type;
    if (e?.target?.checked) {
      type.push(item.id);
    } else {
      var i = type.indexOf(item.id);
      if (i !== -1) {
        type.splice(i, 1);
      }
    }
    props.setFilterFlight({
      ...props.filterFlight,
      type: type,
    });
  };

  const onChangeMaskapai = (e, item) => {
    const maskapai = props.filterFlight?.maskapai;
    if (e?.target?.checked) {
      maskapai.push(item.code);
    } else {
      var i = maskapai.indexOf(item.code);
      if (i !== -1) {
        maskapai.splice(i, 1);
      }
    }
    props.setFilterFlight({
      ...props.filterFlight,
      maskapai: maskapai,
    });
  };

  const onChangeStartTime = (e, item) => {
    const start = props.filterFlight?.startTime;
    if (e?.target?.checked) {
      start.push(item.id);
    } else {
      var i = start.indexOf(item.id);
      if (i !== -1) {
        start.splice(i, 1);
      }
    }
    props.setFilterFlight({
      ...props.filterFlight,
      startTime: start,
    });
  };

  const onChangeEndTime = (e, item) => {
    const end = props.filterFlight?.endTime;
    if (e?.target?.checked) {
      end.push(item.id);
    } else {
      var i = end.indexOf(item.id);
      if (i !== -1) {
        end.splice(i, 1);
      }
    }
    props.setFilterFlight({
      ...props.filterFlight,
      endTime: end,
    });
  };

  const onChangeFacility = (e, item) => {
    const facility = props.filterFlight?.facility;
    if (e?.target?.checked) {
      facility.push(item.id);
    } else {
      var i = facility.indexOf(item.id);
      if (i !== -1) {
        facility.splice(i, 1);
      }
    }
    props.setFilterFlight({
      ...props.filterFlight,
      facility: facility,
    });
  };

  return (
    <Card className="border-0 shadow mb-5">
      <Card.Body>
        {/* <div className="d-flex align-items-center justify-content-between py-3">
          <span>Filter</span>
          <span
            className="text-primary"
            role="button"
            onClick={() => props.setFilterFlight(props?.defaultFilter)}
          >
            Reset
          </span>
        </div> */}
        <div className="filter-search">
          <Accordion defaultActiveKey={["0", "1", "2", "3", "4"]} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="mb-3">Transit</Accordion.Header>
              <Accordion.Body>
                {type &&
                  type.map((item, index) => (
                    <Form.Check
                      key={index}
                      reverse
                      label={item.name}
                      name={`group-${item.id}`}
                      type="checkbox"
                      id={`reverse-checkbox-${item.id}`}
                      checked={props?.filterFlight?.type?.includes(item.id)}
                      onChange={(checked) => {
                        onChangeType(checked, item);
                      }}
                    />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>{t("price_range")}</Accordion.Header>
              <Accordion.Body>
                <div className="d-flex mb-2 align-items-center price">
                  <Form.Control
                    placeholder="Start Price"
                    aria-label="Start Price"
                    aria-describedby="start-price"
                    className="p-1"
                    value={new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(props?.filterFlight?.startPrice)}
                    readOnly
                  />
                  <div className="line-middle"></div>
                  <Form.Control
                    placeholder="End Price"
                    aria-label="End Price"
                    aria-describedby="end-price"
                    className="p-1"
                    value={new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(props?.filterFlight?.endPrice)}
                    readOnly
                  />
                </div>
                <MultiRangeSlider
                  baseClassName={"multi-range-slider"}
                  label={false}
                  ruler={false}
                  min={price[0]}
                  max={price[1]}
                  step={100000}
                  stepOnly={true}
                  minValue={props?.filterFlight?.startPrice}
                  maxValue={props?.filterFlight?.endPrice}
                  onChange={(e) => {
                    handlePrice(e);
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>{t("time_to_go")}</Accordion.Header>
              <Accordion.Body>
                {startDate &&
                  startDate.map((item, index) => (
                    <Form.Check
                      key={index}
                      reverse
                      label={
                        <div className="d-flex align-items-center text-center">
                          <div style={{ width: "50px" }}>{item?.start}</div>
                          <div style={{ width: "10px" }}>-</div>
                          <div style={{ width: "50px" }}>{item?.end}</div>
                        </div>
                      }
                      name={`group-${item.id}`}
                      type="checkbox"
                      id={`reverse-checkbox-${item.id}`}
                      checked={props?.filterFlight?.startTime?.includes(
                        item.id
                      )}
                      onClick={(checked) => {
                        onChangeStartTime(checked, item);
                      }}
                    />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>{t("time_arrives")}</Accordion.Header>
              <Accordion.Body>
                {endDate &&
                  endDate.map((item, index) => (
                    <Form.Check
                      key={index}
                      reverse
                      label={
                        <div className="d-flex align-items-center text-center">
                          <div style={{ width: "50px" }}>{item?.start}</div>
                          <div style={{ width: "10px" }}>-</div>
                          <div style={{ width: "50px" }}>{item?.end}</div>
                        </div>
                      }
                      name={`group-${item.id}`}
                      type="checkbox"
                      id={`reverse-checkbox-${item.id}`}
                      checked={props?.filterFlight?.endTime?.includes(item.id)}
                      onClick={(checked) => {
                        onChangeEndTime(checked, item);
                      }}
                    />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>{t("airline")}</Accordion.Header>
              <Accordion.Body>
                {maskapai &&
                  maskapai.map((item, index) => (
                    <Form.Check
                      key={index}
                      reverse
                      label={
                        <div className="d-flex align-items-center">
                          {AirlineImageConverter(item?.code)}
                          <div>{item.name}</div>
                        </div>
                      }
                      name={`group-${item.code}`}
                      type="checkbox"
                      id={`reverse-checkbox-${item.code}`}
                      checked={props?.filterFlight?.maskapai?.includes(
                        item.code
                      )}
                      onClick={(checked) => {
                        onChangeMaskapai(checked, item);
                      }}
                    />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>{t("facility")}</Accordion.Header>
              <Accordion.Body>
                {facility &&
                  facility.map((item, index) => (
                    <Form.Check
                      key={index}
                      reverse
                      label={item?.name}
                      name={`group-${item.id}`}
                      type="checkbox"
                      id={`reverse-checkbox-${item.id}`}
                      checked={props?.filterFlight?.facility?.includes(
                        item.id
                      )}
                      onClick={(checked) => {
                        onChangeFacility(checked, item);
                      }}
                    />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Card.Body>
    </Card>
  );
}

export default FilterFlight;
