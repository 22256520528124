import React, { useContext, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Card, Form } from "react-bootstrap";
import ReactSelect from "react-select";
import FooterNavigation from "../footerNavigation";
import { useForm } from "react-hook-form";
import UserContext from "../../../../../services/user";

const schema = yup
  .object({
    // communityMember: yup.string().required(),
    socialMedia: yup.string().required("Social Media is required"),
    activityWithCommunity: yup.string().required("Activity With Community is required"),
  })
  .required();

function Step6(params) {
  const { data, registerForm } = useContext(UserContext);
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitStep = async (values) => {
    const newData = Object.assign(data, values);
    const response = await registerForm(newData);
    if (response) {
      params.nextStep();
    }
  };

  return (
    <Card className="card-step border-0 shadow-sm">
      <Card.Body>
        <div className="pb-2">Community Members Followed (Optional)</div>
        <div className="mb-3">
          <Form.Control
            as="textarea"
            className="control-input"
            placeholder="Community Members Followed"
            {...register("communityMember")}
          />
          <small className="text-danger">
            {errors.communityMember?.message}
          </small>
        </div>
        <div className="mb-3">
          <div className="pb-2 mt-4">Social Media Account</div>
          <Form.Control
            as="textarea"
            className="control-input"
            placeholder="Social Media Account"
            {...register("socialMedia")}
          />
          <small className="text-danger">{errors.socialMedia?.message}</small>
        </div>
        <div className="mb-3">
          <div className="mt-4">
            <div className="pb-1">Your Activity</div>
            <div className="small text-grey">
              Activity / Traveling Ever Done Alone or With Community
            </div>
          </div>
        </div>
        <div className="mb-3">
          <Form.Control
            as="textarea"
            className="control-input"
            placeholder="Your Activity"
            {...register("activityWithCommunity")}
          />
          <small className="text-danger">
            {errors.activityWithCommunity?.message}
          </small>
        </div>
        <FooterNavigation
          totalSteps={params.totalSteps}
          currentStep={params.currentStep}
          previousStep={params.previousStep}
          nextStep={handleSubmit(submitStep)}
          disabled={false}
          submit={params.submit}
        />
      </Card.Body>
    </Card>
  );
}

export default Step6;
