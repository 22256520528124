import React from "react";
import { BrowserView } from "react-device-detect";
import TravelAssistanceDesktopPage from "./desktop";

function TravelAssistancePage() {
  return (
    // <BrowserView>
      <TravelAssistanceDesktopPage />
    // </BrowserView>
  );
}
export default TravelAssistancePage;
