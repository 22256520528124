import React, { useContext, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import FooterNavigation from "../footerNavigation";
import UserContext from "../../../../../services/user";

function Step5(params) {
  const { data, registerForm } = useContext(UserContext);
  const [error, setError] = useState(false);
  const [other, setOther] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [langauge, setLanguage] = useState([
    "Indonesian",
    "English",
    "Japan",
    "Germany",
    "Mandarin / Chinese",
    "Korean",
    "Others",
  ]);

  const submitStep = async () => {
    if (selectedLanguage.length < 1) {
      setError(true);
    } else {
      setError(false);
      let newSelected = selectedLanguage;
      if (selectedLanguage.includes("Others")) {
        const otherData = other.split(";").filter((item) => {
          return item !== "";
        });
        newSelected = newSelected.concat(otherData);

        var index = selectedLanguage.indexOf("Others");
        if (index !== -1) {
          newSelected.splice(index, 1);
        }
      }
      const newData = Object.assign(data, { languages: newSelected });
      const response = await registerForm(newData);
      if (response) {
        params.nextStep();
      }
    }
  };

  return (
    <Card className="card-step border-0 shadow-sm">
      <Card.Body>
        <div className="pb-3">Language Spoken</div>
        <div className="mb-3 p-4 custom-checkbox-2">
          <Row>
            {langauge &&
              langauge.map((item, index) => (
                <Col
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                  className="radio-group mb-2"
                  key={index}
                >
                  <Form.Check
                    type="checkbox"
                    id={`language-${index}`}
                    label={item}
                    value={item}
                    checked={selectedLanguage.includes(item)}
                    onChange={(input) =>
                      input.target.checked
                        ? setSelectedLanguage([
                            ...selectedLanguage,
                            input.target.value,
                          ])
                        : setSelectedLanguage(
                            selectedLanguage.filter(
                              (item) => item !== input.target.value
                            )
                          )
                    }
                  />
                </Col>
              ))}
            {selectedLanguage.includes("Others") && (
              <Col sm="12" md="12" lg="12" xl="12" className="my-3">
                <div className="small text-grey pb-2">
                  Mention other languages, separated by semicolon
                </div>
                <Form.Control
                  className="control-input mb-3"
                  placeholder="Other Language"
                  onChange={(evt) => setOther(evt.target.value)}
                />
              </Col>
            )}
          </Row>
        </div>
        {error && (
          <small className="text-danger">
            Langauges are required. Minimum 1
          </small>
        )}

        <FooterNavigation
          totalSteps={params.totalSteps}
          currentStep={params.currentStep}
          previousStep={params.previousStep}
          nextStep={submitStep}
          disabled={false}
          submit={params.submit}
        />
      </Card.Body>
    </Card>
  );
}

export default Step5;
