import moment from "moment";
import { Button, Card, Image } from "react-bootstrap";
import FlightIcon from "../../../../assets/icons/flight.svg";
import { useTranslation } from "react-i18next";

function FlightInfo(params) {
  const { t } = useTranslation();
  return (
    <Card className="w-100 border-0 rounded-4 shadow-sm flight-info">
      <Card.Body className="d-flex align-items-center justify-content-between py-4">
        <div className="d-flex">
          <Image src={FlightIcon} className="img-responsive" />
          <div className="ms-3">
            <div className="text-primary mb-1 fs-5">
              {params.type === 0
                ? `${params.data?.fromcity} -> ${params.data?.tocity}`
                : `${params.data?.tocity} -> ${params.data?.fromcity}`}
            </div>
            <div className="fw-light block-detail-search">
              <div className="me-1">
                &#x2022;{" "}
                {params.type === 0
                  ? moment(params?.data?.departdate).format("ddd, DD MMM YYYY")
                  : moment(params?.data?.returndate).format(
                      "ddd, DD MMM YYYY"
                    )}{" "}
              </div>
              <div className="me-1">
                &#x2022;{" "}
                {parseInt(params?.data?.adult) +
                  parseInt(params?.data?.child) +
                  parseInt(params?.data?.infant)}{" "}
                Passanger
              </div>
              <div>
                &#x2022;{" "}
                {params?.data?.cabin
                  ?.replace(/-/g, " ")
                  .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button
            variant="primary"
            className="text-white"
            onClick={() => window.scrollTo(0, 0)}
          >
            {t("change_search")}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
export default FlightInfo;
