import { Image } from "react-bootstrap";
import data from "../../assets/maskapai.json";

export function AirlineConverter(code) {
  const newData = data.find((o) => o.code === code);
  if (newData) {
    return (
      <div className="d-flex align-items-center">
        <Image
          style={{ maxWidth: "50px" }}
          src={`/assets/airlines/${newData?.code}.png`}
        />
        <div className="ms-2">{newData?.name}</div>
      </div>
    );
  } else {
    return code;
  }
}

export function AirlineNameConverter(code) {
  const newData = data.find((o) => o.code === code);
  if (newData) {
    return newData?.name;
  } else {
    return code;
  }
}

export function AirlineImageConverter(code) {
  const newData = data.find((o) => o.code === code);
  if (newData) {
    return (
      <Image
        style={{ maxWidth: "25px" }}
        className="me-1"
        src={`/assets/airlines/${newData?.code}.png`}
      />
    );
  }
}
